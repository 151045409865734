import React from "react"

import "./style.scss"
import CheckmarkIcon from "../../images/assets/Radio/Checkmark.svg"

const PrescriptionRadio = ({ value, label, onClick, color, active, error, clearError }) => {
  const isInactive = value !== "bothEyes" && (active && active !== value)
  const isActive = value === "bothEyes" ?  active : (active && active === value)
  const handleCheckboxClick = () => {
    onClick(value)
    clearError?.()
  }

  return (
    <label
      className="prescription-radio"
      htmlFor={value}
      data-color={color}
      aria-checked={isActive}
      aria-disabled={isInactive}
    >
      <span className={`checkbox ${error ? 'checkbox-error' : ''}`}>{isActive && <CheckmarkIcon />}</span>
      <input
        disabled={isInactive}
        type="radio"
        value={value}
        id={value}
        onClick={handleCheckboxClick}
      />
      <span className="title text h8" dangerouslySetInnerHTML={{ __html: label }}></span>
    </label>
  )
}

export default PrescriptionRadio
