import React, { useState, useContext, useEffect } from "react"
import Select, { components } from "react-select"
import axios from "axios"

import "./styles.scss"
import { states } from "./mockedData"
import HubbleOrderContext from "../../../context/hubbleOrderContext"
import StepWrapper from "../StepWrapper"
import WebpImage from "../../WebpImage"

const DoctorV3 = ({ steps, setActiveSteps, isV3, isAvg, isInvoice, initialValues }) => {
  const { doctor, setDoctor, requiredError } = useContext(HubbleOrderContext)
  const [selectedState, setSelectedState] = useState("")
  const [doctorsList, setDoctorsList] = useState([])
  const [selectedDoctor, setSelectedDoctor] = useState("")
  const [isError, setIsError] = useState(false)
  const [isFindDoctor, setIsFindDoctor] = useState(false)
  const [isFindDoctorError, setIsFindDoctorError] = useState(false)
  const [doctorValues, setDoctorValues] = useState({
    name: "",
    clinic_name: "",
    phone: "",
    city: "",
    isNew: true,
  })

  useEffect(() => {
    if (isFindDoctor) {
      setDoctor({...doctorValues})
    } else {
      setDoctor(selectedDoctor)
    }
  }, [isFindDoctor])

  const handleInputChange = e => {
    const { name, value } = e.target
    setDoctorValues(prevState => ({ ...prevState, [name]: value }))
    setDoctor({ ...doctorValues, [name]: value })
  }

  const handleSubmit = () => {
    setIsError(false)
    setIsFindDoctorError(false)

    const currentRef = steps.find(({ title }) => title === "Checkout")

    if (isFindDoctor) {
      if (Object.values(doctorValues).some(value => !value)) {
        return setIsFindDoctorError(true)
      }

      return createDoctor(doctorValues).then(response => {
        setDoctor({
          id: response.data.data.doc.id,
          ...doctorValues,
        })
        setActiveSteps({ Checkout: true })
        setTimeout(() => {
          if (isInvoice) return
          currentRef.ref.current.scrollIntoView({ behavior: "smooth" })
        }, 200)
      })
    }

    if (!selectedState || !selectedDoctor) return setIsError(true)

    setDoctor(selectedDoctor)
    setActiveSteps({ Checkout: true })
    setTimeout(() => {
      if (isInvoice) return
      currentRef.ref.current.scrollIntoView({ behavior: "smooth" })
    }, 200)
  }

  const getDoctors = state =>
    axios.get(
      `https://api.hubblecontacts.com/v1/docs/search.json?state=${state}`
    )

  const createDoctor = doctor =>
    axios.post(`${process.env.GATSBY_ADMIN_DOC_URL}/v1/docs.json`, doctor, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    })

  const handleSelectState = e => {
    setSelectedState(e)
    setDoctorsList([])
    setSelectedDoctor("")
    setDoctorValues(prevState => ({...prevState, state: e?.value}))

    getDoctors(e.value).then(response => {
      const newDoctorsList = response.data.data.docs.map(doc => ({
        ...doc,
        value: doc.id,
      }))
      setDoctorsList(newDoctorsList)
    })
  }

  const handleSelectDoctor = e => {
    setSelectedDoctor(e)
    setDoctor(e)
    if (typeof window !== "undefined") {
      window.dataLayer.push({
        event: "find_my_doctor",
        doctor_state: e?.state,
        doctor_name: e?.name,
      })
    }
  }

  const customFilter = (option, inputValue) => {
    const { name, clinic_name, postcode } = option.data

    return (
      !inputValue ||
      `${name} ${clinic_name} ${postcode}`.toLowerCase().includes(inputValue)
    )
  }

  useEffect(() => {
    if (selectedState && selectedDoctor) {
      handleSubmit()
      setIsError(false)
    }
  }, [selectedState, selectedDoctor])

  useEffect(() => {
    if (initialValues) {
      handleSelectState(initialValues.state)
      setTimeout(() => {
        handleSelectDoctor(initialValues.doctor)
      },0)
    }
  }, []);

  const searchIcon = () => {
    const image = "Pages/Intake/Search-icon.svg"
    return <WebpImage fileName={image} />
  }

  return (
    <StepWrapper
      title="Find Your Eye Doctor"
      steps={steps}
      currentStep="Doctor"
      onSubmit={handleSubmit}
      className="doctor-v3"
      isV3={isV3}
      isAvg={isAvg}
    >
      <div className="subtitle-wrapper">
        <p className="doctor-subtitle">
          We’ll contact your doctor for you to dot the i’s and cross the t’s.
        </p>
        <p className="doctor-subtitle">
          We’ll let you know via email when everything is all set.
        </p>
      </div>
      <div className="state-container">
        <label>My doctor’s home state</label>
        <Select
          className={`hubble-select doctor-state ${
            (isError || requiredError) && !selectedState ? "error" : ""
          }`.trim()}
          classNamePrefix="HubbleSelect"
          value={selectedState}
          onChange={handleSelectState}
          placeholder="Select State"
          options={states}
        />
      </div>
      <div className="doctor-container">
        <label>Available doctors</label>
        <Select
          className={`hubble-select doctor ${
            (isError || requiredError) && !selectedDoctor && !isFindDoctor ? "error" : ""
          }`.trim()}
          classNamePrefix="HubbleSelect"
          value={!isFindDoctor && selectedDoctor}
          onChange={handleSelectDoctor}
          placeholder="Search by doctor, clinic name, or zip code"
          options={doctorsList}
          isDisabled={!doctorsList.length || isFindDoctor}
          components={{
            Option: CustomOption,
            SingleValue: CustomSingleValue,
            DropdownIndicator: searchIcon,
          }}
          filterOption={customFilter}
        />
      </div>
      <div className="doctor-issue-wrapper">
        <input
          type="radio"
          id="doctor-issue"
          value="doctor-issue"
          name="doctor-issue"
          onClick={() => setIsFindDoctor(!isFindDoctor)}
          checked={isFindDoctor}
          disabled={!selectedState}
        />
        <label className="doctor-issue" for="doctor-issue">
          Can’t find your doctor?
        </label>
      </div>
      {isFindDoctor && (
        <div className="find-doctor-container">
          <div className="inputs-container">
            <input
              type="text"
              name="name"
              className={`form-input ${
                (isFindDoctorError || requiredError) && !doctorValues.name ? "error" : ""
              }`.trim()}
              onChange={e => handleInputChange(e)}
              value={doctorValues.name}
              placeholder="Doctor's Name"
              maxLength={64}
            />
            <input
              type="text"
              name="clinic_name"
              className={`form-input ${
                (isFindDoctorError || requiredError) && !doctorValues.clinic_name ? "error" : ""
              }`.trim()}
              onChange={e => handleInputChange(e)}
              value={doctorValues.clinic_name}
              placeholder="Clinic Name"
              maxLength={64}
            />
            <input
              type="text"
              name="phone"
              className={`form-input ${
                (isFindDoctorError || requiredError) && !doctorValues.phone ? "error" : ""
              }`.trim()}
              onChange={e => handleInputChange(e)}
              value={doctorValues.phone}
              placeholder="Phone"
              maxLength={18}
            />
            <input
              type="text"
              name="city"
              className={`form-input ${
                (isFindDoctorError || requiredError) && !doctorValues.city ? "error" : ""
              }`.trim()}
              onChange={e => handleInputChange(e)}
              value={doctorValues.city}
              placeholder="City"
              maxLength={64}
            />
          </div>
          <a
            className="doctor-issue cancel"
            onClick={() => setIsFindDoctor(false)}
          >
            Cancel
          </a>
        </div>
      )}
    </StepWrapper>
  )
}

const CustomOption = props => {
  const { clinic_name, name, postcode } = props.data

  return (
    <components.Option {...props}>
      <div className="name">Name: {name}</div>
      <div className="clinic-name">Clinic Name: {clinic_name}</div>
      <div className="zip">ZIP: {postcode}</div>
    </components.Option>
  )
}

const CustomSingleValue = props => {
  const { name, postcode } = props.data

  return (
    <components.SingleValue {...props}>
      <div className="name">{name}</div>
      <div className="zip">{postcode}</div>
    </components.SingleValue>
  )
}

export default DoctorV3
