import {useContext} from "react";
import AbTestsContext from "../context/abTestsContext";

const useTest = (testName) => {
  const {getVariant} = useContext(AbTestsContext)

  return getVariant(testName)
}

export default useTest;
