export const productsClassic = [
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Lenses",
    image: "",
    price: "20.00",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "30",
  },
  {
    value: "brand-acuvue-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
    title: "Acuvue Oasys for Astigmatism 6pk",
    image: "30_off_AcuvueOasysforAstigmatism6pk_front.jpg",
    price: "59.99",
    sale_price: "45.00",
    cc_product_id: "1765080399972",
    days: "90",
  },
  {
    value: "brand-acuvue-moist",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
    title: "1-Day Acuvue Moist",
    image: "30-off-1-DayAcuvueMoist90pk_front.jpg",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "2620861448292",
    days: "88",
  },
  {
    value: "brand-acuvue-oyasis-hydraluxe",
    percent_off: "20",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
    title: "Acuvue Oasys 1-Day with Hydraluxe",
    image: "25-off-AcuvueOasys1-DaywithHydraluxe90pk_front.jpg",
    price: "104.99",
    sale_price: "84.00",
    cc_product_id: "1765069357156",
    days: "90",
  },
  {
    value: "brand-dailies-aquacomfort-plus",
    percent_off: "25",
    link: "https://www.contactscart.com/products/dailies-aqua-comfort-plus-90pk",
    title: "DAILIES AquaComfort Plus",
    image: "30-off-DAILIESAquaComfortPlus90pk_front.jpg",
    price: "74.99",
    sale_price: "56.25",
    cc_product_id: "1765114970212",
    days: "88",
  },
  {
    value: "brand-biotrue-presbyopia",
    percent_off: "20",
    link: "https://www.contactscart.com/products/biofinity-toric",
    title: "Biotrue ONEday for Presbyopia 90pk",
    image: "945_BiotrueONEdayforPresbyopia90pk_front_60.jpg",
    price: "99.99",
    sale_price: "80.00",
    cc_product_id: "3408938401892",
    days: "88",
  },
  {
    value: "brand-acuvue-moist-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
    title: "1-Day Acuvue Moist for Astigmatism 30pk",
    image: "25-off-DayAcuvueMoistforAstigmatism30pk_front.jpeg",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "1765072797796",
    days: "30",
  },
  {
    value: "brand-acuvue-hydraclear-plus",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus 6pk",
    image: "30-off-AcuvueOasyswithHydraclearPlus6pk_front.jpg",
    price: "45.99",
    sale_price: "34.50",
    cc_product_id: "1765078499428",
    days: "90",
  },
  {
    value: "dailies-total-one",
    percent_off: "20",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "DAILIES TOTAL1 90pk",
    image: "25-off-DAILIESTotal1_90pk_front.jpeg",
    price: "114.99",
    sale_price: "92.00",
    cc_product_id: "2620910534756",
    days: "88",
  },
  {
    value: "biotrue-oneday",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday 90pk",
    image: "30-off-BiotrueONEday90pk_front.jpg",
    price: "69.99",
    sale_price: "52.50",
    cc_product_id: "1765111332964",
    days: "88",
  },
];

export const productsAffiliatePage = [
  {
    value: "hydro",
    percent_off: "0",
    link: "",
    title: "Hydro by Hubble Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: "24.99",
    sale_price: "4.99",
    cc_product_id: "hydro",
    days: "30",
  },
  {
    value: "skyhy",
    percent_off: "0",
    link: "",
    title: "SkyHy by Hubble Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: "29.99",
    sale_price: "9.99",
    cc_product_id: "skyhy",
    days: "30",
  },
  {
    value: 'contactscart',
    title: "Browse 50+ more brands at ContactsCart.com",
    price: "",
    link: "https://www.contactscart.com/",
  },
]

export const productsHydroSkyhy = () => {
  return(
    [
      {
        value: "hubble",
        percent_off: "0",
        link: "",
        title: "Hubble Classic Lenses",
        image: "",
        price: "20.00",
        sale_price: "1.00",
        cc_product_id: "hubble",
        days: "30",
        quantity: 30
      },
      {
        value: "hydro",
        percent_off: "0",
        link: "",
        title: "Hydro by Hubble Contact Lenses",
        short_title: "Hydro By Hubble",
        image: "",
        price: "24.99",
        sale_price: "4.99",
        cc_product_id: "hydro",
        days: "30",
        quantity: 30
      },
      {
        value: "skyhy",
        percent_off: "0",
        link: "",
        title: "SkyHy by Hubble Contact Lenses",
        short_title: "SkyHy by Hubble",
        image: "",
        price: "29.99",
        sale_price: "9.99",
        cc_product_id: "skyhy",
        days: "30",
        quantity: 30
      },
      {
        value: "brand-acuvue-astigmatism",
        percent_off: "25",
        link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
        title: "Acuvue Oasys for Astigmatism 6pk",
        image: "Acuvue-Oasys-for-Astigmatism-6pk.png",
        price: "59.99",
        sale_price: "45.00",
        cc_product_id: "1765080399972",
        days: "90",
        quantity: 6
      },
      {
        value: "brand-acuvue-moist",
        percent_off: "25",
        link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
        title: "1-Day Acuvue Moist",
        image: "1-Day-Acuvue-Moist-90pk.png",
        price: "79.99",
        sale_price: "60.00",
        cc_product_id: "2620861448292",
        days: "88",
        quantity: 90
      },
      {
        value: "brand-acuvue-oyasis-hydraluxe",
        percent_off: "20",
        link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
        title: "Acuvue Oasys 1-Day with Hydraluxe",
        image: "Acuvue-Oasys-1-Day-With-Hydraluxe.png",
        price: "104.99",
        sale_price: "84.00",
        cc_product_id: "1765069357156",
        days: "90",
        quantity: 90
      },
      {
        value: "brand-dailies-aquacomfort-plus",
        percent_off: "25",
        link: "https://www.contactscart.com/products/dailies-aqua-comfort-plus-90pk",
        title: "DAILIES AquaComfort Plus",
        image: "DAILIES-AquaComfort-Plus.png",
        price: "74.99",
        sale_price: "56.25",
        cc_product_id: "1765114970212",
        days: "88",
        quantity: 90
      },
      {
        value: "brand-biotrue-presbyopia",
        percent_off: "20",
        link: "https://www.contactscart.com/products/biofinity-toric",
        title: "Biotrue ONEday for Presbyopia 90pk",
        image: "Biotrue-ONEday-Presbyopia-90pk.png",
        price: "99.99",
        sale_price: "80.00",
        cc_product_id: "3408938401892",
        days: "88",
        quantity: 90
      },
      {
        value: "brand-acuvue-moist-astigmatism",
        percent_off: "25",
        link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
        title: "1-Day Acuvue Moist for Astigmatism 30pk",
        image: "1-Day-Acuvue-Moist-Astigmatism-30pk.png",
        price: "49.99",
        sale_price: "37.50",
        cc_product_id: "1765072797796",
        days: "30",
        quantity: 30
      },
      {
        value: "brand-acuvue-hydraclear-plus",
        percent_off: "25",
        link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
        title: "Acuvue Oasys with Hydraclear Plus 6pk",
        image: "Acuvue-Oasys-Hydraclear-Plus-6pk.png",
        price: "45.99",
        sale_price: "34.50",
        cc_product_id: "1765078499428",
        days: "90",
        quantity: 6
      },
      {
        value: "dailies-total-one",
        percent_off: "20",
        link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
        title: "DAILIES TOTAL1 90pk",
        image: "DAILIES-TOTAL1-90pk.png",
        price: "114.99",
        sale_price: "92.00",
        cc_product_id: "2620910534756",
        days: "88",
        quantity: 90
      },
      {
        value: "biotrue-oneday",
        percent_off: "25",
        link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
        title: "Biotrue ONEday 90pk",
        image: "Biotrue-ONEday-90pk.png",
        price: "69.99",
        sale_price: "52.50",
        cc_product_id: "1765111332964",
        days: "88",
        quantity: 90
      },
    ]
  )
}

export const productsIntake = (isPromo) => [
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Lenses",
    image: "",
    price: "20.00",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "30",
    quantity: 30
  },
  {
    value: "hydro",
    percent_off: "0",
    link: "",
    title: "Hydro by Hubble Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: "24.99",
    sale_price: "4.99",
    cc_product_id: "hydro",
    days: "30",
    quantity: 30
  },
  {
    value: "skyhy",
    percent_off: "0",
    link: "",
    title: "SkyHy by Hubble Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: "29.99",
    sale_price: "9.99",
    cc_product_id: "skyhy",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-astigmatism",
    percent_off: "35",
    link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
    title: "Acuvue Oasys for Astigmatism 6pk",
    image: "Acuvue-Oasys-Astigmatism-6pk",
    price: "52.99",
    sale_price: "34.44",
    cc_product_id: "1765080399972",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-moist",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
    title: "1-Day Acuvue Moist 90pk",
    image: "1-Day-Acuvue-Moist-90pk",
    price: "82.99",
    sale_price: "62.25",
    cc_product_id: "2620861448292",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-oyasis-hydraluxe",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
    title: "Acuvue Oasys 1-Day with Hydraluxe 90pk",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-90pk",
    price: "99.99",
    sale_price: "75.00",
    cc_product_id: "1765069357156",
    days: "90",
    quantity: 90
  },
  {
    value: "brand-dailies-aquacomfort-plus",
    percent_off: "25",
    link: "https://www.contactscart.com/products/dailies-aqua-comfort-plus-90pk",
    title: "DAILIES AquaComfort Plus",
    image: "DAILIES-AquaComfort-Plus.png",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "1765114970212",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-biotrue-presbyopia",
    percent_off: "25",
    link: "https://www.contactscart.com/products/biofinity-toric",
    title: "Biotrue ONEday for Presbyopia 90pk",
    image: "Biotrue-ONEDay-Presbyopia-90pk",
    price: "96.99",
    sale_price: "72.75",
    cc_product_id: "3408938401892",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-moist-astigmatism",
    percent_off: "35",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
    title: "1-Day Acuvue Moist for Astigmatism 30pk",
    image: "1-Day-Acuvue-Moist-Astigmatism-30pk",
    price: "44.99",
    sale_price: "29.24",
    cc_product_id: "1765072797796",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-hydraclear-plus",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus 6pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-6pk",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "1765078499428",
    days: "90",
    quantity: 6
  },
  {
    value: "dailies-total-one",
    percent_off: "20",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "DAILIES TOTAL1 90pk",
    image: "DAILIES-TOTAL1-90pk.png",
    price: "112.99",
    sale_price: "90.40",
    cc_product_id: "2620910534756",
    days: "88",
    quantity: 90
  },
  {
    value: "biotrue-oneday-90",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday 90pk",
    image: "Biotrue-ONEDay-90pk",
    price: "67.99",
    sale_price: "51.00",
    cc_product_id: "1765111332964",
    days: "88",
    quantity: 90
  },
]

export const productsInvoice = [
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Lenses",
    short_title: "Classic By Hubble",
    image: "",
    price: "20.00",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "30",
    quantity: 30
  },
  {
    value: "hydro",
    percent_off: "0",
    link: "",
    title: "Hydro by Hubble Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: "24.99",
    sale_price: "4.99",
    cc_product_id: "hydro",
    days: "30",
    quantity: 30
  },
  {
    value: "skyhy",
    percent_off: "0",
    link: "",
    title: "SkyHy by Hubble Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: "29.99",
    sale_price: "9.99",
    cc_product_id: "skyhy",
    days: "30",
    quantity: 30
  },
  {
    id: "1765080399972",
    description: "Acuvue Oasys for Astigmatism lenses have a base curve of 8.6 and a diameter of 14.5. They are made of senofilcon A and manufactured by Johnson & Johnson.",
    value: "brand-acuvue-astigmatism",
    percent_off: "35",
    sku: "AVOA",
    title: "Acuvue Oasys for Astigmatism 6pk",
    image: "Acuvue-Oasys-Astigmatism-6pk",
    price: "52.99",
    sale_price: "34.44",
    cc_product_id: "1765080399972",
    days: "90",
    quantity: 6,
    baseCurve: 8.6,
    diameter: 14.5,
    cadence: 84
  },
  {
    id: "1765072797796",
    description: "1-Day Acuvue Moist for Astigmatism lenses have a base curve of 8.5 and a diameter of 14.5. They are made of etafilcon A and manufactured by Johnson & Johnson.",
    value: "brand-acuvue-moist-astigmatism",
    percent_off: "35",
    title: "1-Day Acuvue Moist for Astigmatism 30pk",
    sku: "ACM1DAYASTIG",
    image: "1-Day-Acuvue-Moist-Astigmatism-30pk",
    price: "44.99",
    sale_price: "29.24",
    cc_product_id: "1765072797796",
    days: "30",
    quantity: 30,
    baseCurve: 8.5,
    diameter: 14.5,
    cadence: 28
  },
  {
    id: "3344340287588",
    description: "1-Day Acuvue Moist for Astigmatism lenses have a base curve of 8.5 and a diameter of 14.5. They are made of etafilcon A and manufactured by Johnson & Johnson.",
    value: "brand-acuvue-moist-astigmatism-90",
    percent_off: "35",
    sku: "AC1DMASTIG90",
    title: "1-Day Acuvue Moist for Astigmatism 90pk",
    image: "1-Day-Acuvue-Moist-Astigmatism-90pk",
    price: "104.99",
    sale_price: "68.24",
    cc_product_id: "3344340287588",
    days: "88",
    quantity: 90,
    baseCurve: 8.5,
    diameter: 14.5,
    cadence: 84
  },
  {
    id: "3409322016868",
    description: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism lenses have a base curve of 8.5 and a diameter of 14.3. They are made of senofilcon A and manufactured by Johnson & Johnson.",
    value: "acuvue-oasys-1-day-hydraluxe-astig-30",
    percent_off: "35",
    sku: "AC1DOA30",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 30pk",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-30pk",
    price: "54.99",
    sale_price: "35.74",
    cc_product_id: "3409322016868",
    days: "30",
    quantity: 30,
    baseCurve: 8.5,
    diameter: 14.3,
    cadence: 28
  },
  {
    id: "7165851992164",
    description: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism lenses have a base curve of 8.5 and a diameter of 14.3. They are made of senofilcon A and manufactured by Johnson & Johnson.",
    value: "acuvue-oasys-1-day-hydraluxe-astig-90",
    percent_off: "35",
    sku: "AC1DOA90",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 90pk",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-90pk",
    price: "126.99",
    sale_price: "82.54",
    cc_product_id: "7165851992164",
    days: "88",
    quantity: 90,
    baseCurve: 8.5,
    diameter: 14.3,
    cadence: 84
  },
  {
    id: "3379060179044",
    description: "Acuvue Vita for Astigmatism lenses have a base curve of 8.6 and a diameter of 14.5. They are made of methyl ether cellulose and manufactured by Johnson & Johnson.",
    value: "brand-acuvue-vita-6pk-astig",
    percent_off: "35",
    sku: "AVA",
    title: "Acuvue Vita for Astigmatism 6pk",
    image: "Acuvue-Vita-Astigmatism-6pk",
    price: "74.99",
    sale_price: "48.74",
    cc_product_id: "3379060179044",
    days: "180",
    quantity: 6,
    baseCurve: 8.6,
    diameter: 14.5,
    cadence: 168
  },
  {
    id: "3341501268068",
    description: "Bausch + Lomb ULTRA for Astigmatism lenses have a base curve of 8.6 and a diameter of 14.5. They are made of samfilcon A and manufactured by Bausch + Lomb.",
    value: "bausch-lomb-astig",
    percent_off: "35",
    sku: "ULTRASTIG6",
    title: "Bausch + Lomb ULTRA for Astigmatism 6pk",
    image: "B+L-Ultra-Astigmatism-6pk",
    price: "69.99",
    sale_price: "45.49",
    cc_product_id: "3341501268068",
    days: "180",
    quantity: 6,
    baseCurve: 8.6,
    diameter: 14.5,
    cadence: 168
  },
  {
    id: "3408913530980",
    description: "Biotrue ONEday Astigmatism lenses have a base curve of 8.4 and a diameter of 14.5. They are made of nesofilcon A and manufactured by Bausch + Lomb.",
    value: "biotrue-oneday-astig-30",
    percent_off: "35",
    title: "Biotrue ONEday Astigmatism 30pk",
    image: "Biotrue-ONEDay-Astigmatism-30pk",
    price: "32.99",
    sale_price: "21.44",
    cc_product_id: "3408913530980",
    days: "30",
    quantity: 30,
    baseCurve: 8.4,
    diameter: 14.5,
    cadence: 28
  },
  {
    id: -1,
    title: "Browse 50+ more brands at contactscart.com",
    price: "",
    value: -1,
    link: "https://www.contactscart.com/",
  },
]




export const avgProductsIntake = [
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Lenses",
    image: "",
    price: "20.00",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "30",
    quantity: 30
  },
  {
    value: "hydro",
    percent_off: "0",
    link: "",
    title: "Hydro by Hubble Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: "24.99",
    sale_price: "4.99",
    cc_product_id: "hydro",
    days: "30",
    quantity: 30
  },
  {
    value: "skyhy",
    percent_off: "0",
    link: "",
    title: "SkyHy by Hubble Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: "29.99",
    sale_price: "9.99",
    cc_product_id: "skyhy",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-moist-30-normal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist",
    title: "1-Day Acuvue Moist 30pk",
    image: "1-Day-Acuvue-Moist-30pk",
    price: "44.99",
    sale_price: "33.75",
    cc_product_id: "1765066866788",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-moist",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
    title: "1-Day Acuvue Moist 90pk",
    image: "1-Day-Acuvue-Moist-90pk",
    price: "82.99",
    sale_price: "62.25",
    cc_product_id: "2620861448292",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-2",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-2",
    title: "Acuvue 2 6pk",
    image: "Acuvue-2-6pk",
    price: "42.99",
    sale_price: "32.25",
    cc_product_id: "1765074927716",
    days: "88",
    quantity: 6
  },
  {
    value: "acuvue-define-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-define-30pk",
    title: "1-Day Acuvue Define 30pk",
    image: "1-Day-Acuvue-Define-30pk-blue",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "3409278664804",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-oyasis-hydraluxe",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
    title: "Acuvue Oasys 1-Day with Hydraluxe 90pk",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-90pk",
    price: "99.99",
    sale_price: "75.00",
    cc_product_id: "1765069357156",
    days: "90",
    quantity: 90
  },
  {
    value: "brand-acuvue-oyasis-max-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-max-1-day-30pk",
    title: "Acuvue Oasys Max 1-Day 30pk",
    image: "Acuvue-Oasys-Max-1-Day-30pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "7173256249444",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-oyasis-max-90",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-max-1-day-30pk",
    title: "Acuvue Oasys Max 1-Day 90pk",
    image: "Acuvue-Oasys-Max-1-Day-90pk",
    price: "127.99",
    sale_price: "96.00",
    cc_product_id: "7430125551716",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-hydraclear-plus",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus 6pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-6pk",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "1765078499428",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-hydraclear-plus-12",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Acuvue Oasys with Hydraclear Plus 12pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-12pk",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "3409786896484",
    days: "180",
    quantity: 12
  },
  {
    value: "brand-acuvue-hydraclear-plus-24",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-24pk",
    title: "Acuvue Oasys with Hydraclear Plus 24pk",
    image: "Acuvue-Oasys-Hydraclear-Plus-24pk",
    price: "149.99",
    sale_price: "112.50",
    cc_product_id: "2620883206244",
    days: "360",
    quantity: 24
  },
  {
    value: "brand-acuvue-vita-12pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita-12pk",
    title: "Acuvue Vita 12pk",
    image: "Acuvue-Vita-12pk",
    price: "109.99",
    sale_price: "82.50",
    cc_product_id: "3409889198180",
    days: "360",
    quantity: 12
  },
  {
    value: "brand-acuvue-vita-6pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita",
    title: "Acuvue Vita 6pk",
    image: "Acuvue-Vita-6pk",
    price: "74.99",
    sale_price: "56.25",
    cc_product_id: "1765082136676",
    days: "180",
    quantity: 6
  },
  {
    value: "biotrue-oneday-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/biotrue-oneday-30pk",
    title: "Biotrue ONEday 30pk",
    image: "Biotrue-ONEDay-30pk",
    price: "29.99",
    sale_price: "22.50",
    cc_product_id: "3408967008356",
    days: "30",
    quantity: 30
  },
  {
    value: "biotrue-oneday-90",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday 90pk",
    image: "Biotrue-ONEDay-90pk",
    price: "67.99",
    sale_price: "51.00",
    cc_product_id: "1765111332964",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-biotrue-presbyopia",
    percent_off: "25",
    link: "https://www.contactscart.com/products/biofinity-toric",
    title: "Biotrue ONEday for Presbyopia 90pk",
    image: "Biotrue-ONEDay-Presbyopia-90pk",
    price: "96.99",
    sale_price: "72.75",
    cc_product_id: "3408938401892",
    days: "88",
    quantity: 90
  },
  {
    value: "infuse-one-day-90pk",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb Infuse One-Day 90pk",
    image: "B+L-Infuse-90pk",
    price: "99.99",
    sale_price: "75.00",
    cc_product_id: "6549011464292",
    days: "88",
    quantity: 90
  },
  {
    value: "bausch-lomb-ultra",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA 6pk",
    image: "B+L-Ultra-6pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "1765135908964",
    days: "180",
    quantity: 6
  },
  {
    value: "bausch-lomb-ultra-presb",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA for Presbyopia 6pk",
    image: "B+L-Ultra-Presbyopia-6pk",
    price: "79.99",
    sale_price: "60.00",
    cc_product_id: "3341054640228",
    days: "180",
    quantity: 6
  },
  {
    value: "brand-acuvue-oasys-transitions-6pk",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-with-transitions-6pk",
    title: "Acuvue Oasys with Transitions 6pk",
    image: "Acuvue-Oasys-Transitions-6pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "4801375567972",
    days: "88",
    quantity: 6
  },
  {
    value: "brand-acuvue-moist-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
    title: "1-Day Acuvue Moist for Astigmatism 30pk",
    image: "1-Day-Acuvue-Moist-Astigmatism-30pk",
    price: "49.99",
    sale_price: "37.50",
    cc_product_id: "1765072797796",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-moist-astigmatism-90",
    percent_off: "25",
    link: "",
    title: "1-Day Acuvue Moist for Astigmatism 90pk",
    image: "1-Day-Acuvue-Moist-Astigmatism-90pk",
    price: "104.99",
    sale_price: "78.75",
    cc_product_id: "3344340287588",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-30",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-for-astigmatism-30pk",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 30pk",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-30pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "3409322016868",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-90",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-with-hydraluxe-for-astigmatism-90pk",
    title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism 90pk",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-90pk",
    price: "124.99",
    sale_price: "93.75",
    cc_product_id: "7165851992164",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-astigmatism",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
    title: "Acuvue Oasys for Astigmatism 6pk",
    image: "Acuvue-Oasys-Astigmatism-6pk",
    price: "59.99",
    sale_price: "45.00",
    cc_product_id: "1765080399972",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-vita-6pk-astig",
    percent_off: "25",
    link: "https://www.contactscart.com/products/acuvue-vita-for-astigmatism",
    title: "Acuvue Vita for Astigmatism 6pk",
    image: "Acuvue-Vita-Astigmatism-6pk",
    price: "74.99",
    sale_price: "56.25",
    cc_product_id: "3379060179044",
    days: "180",
    quantity: 6
  },
  {
    value: "biotrue-oneday-astig-30",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday Astigmatism 30pk",
    image: "Biotrue-ONEDay-Astigmatism-30pk",
    price: "39.99",
    sale_price: "30.00",
    cc_product_id: "3408913530980",
    days: "30",
    quantity: 30
  },
  {
    value: "bausch-lomb-astig",
    percent_off: "25",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA for Astigmatism 6pk",
    image: "B+L-Ultra-Astigmatism-6pk",
    price: "72.99",
    sale_price: "54.75",
    cc_product_id: "3341501268068",
    days: "180",
    quantity: 6
  },
  {
    value: "acuvue-moist-30-multifocal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-multifocal-30pk",
    title: "1-Day Acuvue Moist Multifocal 30pk",
    image: "1-Day-Acuvue-Moist-Multifocal-30pk",
    price: "59.99",
    sale_price: "45.00",
    cc_product_id: "3338889855076",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-moist-90-multifocal",
    percent_off: "25",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-multifocal-90pk",
    title: "1-Day Acuvue Moist Multifocal 90pk",
    image: "1-Day-Acuvue-Moist-Multifocal-90pk",
    price: "114.99",
    sale_price: "86.25",
    cc_product_id: "3341075808356",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-oasys-max-30",
    percent_off: "25",
    link: "",
    title: "Acuvue Oasys Max 1-Day Multifocal 30pk",
    image: "Acuvue-Oasys-Max-1-Day-Multifocal-30pk",
    price: "72.99",
    sale_price: "54.75",
    cc_product_id: "7430134497380",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-oasys-multifocal-6",
    percent_off: "25",
    link: "",
    title: "Acuvue Oasys Multifocal 6pk",
    image: "Acuvue-Oasys-Multifocal-6pk",
    price: "54.99",
    sale_price: "41.25",
    cc_product_id: "6883191193700",
    days: "88",
    quantity: 6
  }


]

