import React, { useState, useContext, useEffect, useCallback} from "react";
import { navigate } from "gatsby";
import StepWrapper from '../StepWrapper';
import HubbleOrderContext from "../../../context/hubbleOrderContext";
import ReactDOM from "react-dom";
import axios from "axios";
import { CardElement,PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import PrescriptionRadio from "../../PrescriptionRadio"
import paypal from 'paypal-checkout';
import braintree from 'braintree-web';
// import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";
import tiktokTrack from "../TiktokTrack";
import "./styles.scss"
import WebpImage from "../../WebpImage";
import { lensesData } from "../../CheckoutCustomerInfo/mockedData";
import { useWindowWidth } from "../../../services/hooks"
import CheckoutRadio from "../../CheckoutRadio"
import CheckoutSelect from "../../CheckoutSelect"
import NewButton from "../../NewButton"
import { states } from '../Doctor/mockedData';
import { zipRegEx } from '../../../utils/variables';
import { getValues, isValidEmail } from '../../../services/utils';
import useAbTest from '../../../utils/useAbTest'
import usePromo from "../../../utils/usePromo";
import { quantityOptionsValues } from "../../ContactsSummary/mockedData";
import CheckoutDuplicateModal from "../../CheckoutDuplicateModal";
import {hubbleClassic} from "../../../services/data";

const CheckoutV3 = ({ steps, isHydroSkyhy, isAffiliatePage ,isIronclad, isAvg, initialValues, isInvoice }) => {
  const {hubbleOrderContext, prescription, doctor, setDoctor, taxes, brand, setBrand, brandSelected, setBrandSelected, setTaxes, shippingAddress, setSubmitButtonText, setShippingAddress, clearShippingAddress, accessories, setAccessories, setRequiredError } = useContext(HubbleOrderContext);
  ReactGA.initialize('UA-74901424-1');
  let search = typeof window !== "undefined" ? window?.location?.search : null
  let params = new URLSearchParams(search);
  let utm_source = params.get('utm_source');
  let utm_medium = params.get('utm_medium');
  let utm_content = params.get('utm_content');
  let utm_campaign_param = params.get('utm_campaign');
  let gclid = params.get("gclid") || params.get("clickid");
  const [submit_copy, setSubmitCopy] = useState("Place Order")
  const [cardErrors, setCardErrors]= useState("")
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paypallPaymentId, setPaypallPaymentId] = useState(null)
  const [ironclad, setIronclad] = useState("")
  const [ironcladWarning, setIronCladWarining] = useState(false)
  const [isMobilePay, setMobilePay] = useState(false)
  const [isMobile, setIsMobile] = useState()
  const [active, setActive] = useState()
  const [currency] = useState('USD')
  const [stripeCountry] = useState('US')
  const [shipping, setShipping] = useState('$0.00')
  const [subTotal, setSubTotal] = useState("0")
  const [total, setTotal] = useState("0")
  const [totalWithShipmentAndTaxes, setTotalWithShipmentAndTaxes] = useState("0")
  const [quantity, setQuantity] = useState(0)
  const [subscriptionDisclaimer, setSubscriptionDisclaimer] = useState('')
  const [isCcProduct, setIsCcProduct] = useState( brand.cc_product_id !== "hubble" && brand.cc_product_id !== "hydro" && brand.cc_product_id !== "skyhy" && brand.cc_product_id !== "classic")
  const [isAddressListShown, setIsAddressListShown] = useState(false)
  const [isEmailValidationError, setIsEmailValidationError] = useState(false)
  const [isFirstTimeEmailEdit, setIsFirstTimeEmailEdit] = useState(true)
  const [emojiErrors, setEmojiErrors] = useState({})
  const isPaidShipping = useAbTest()
  const isPromo = usePromo()
  const isOneEye = prescription.leftEye.power === "oneEye" || prescription.rightEye.power === "oneEye"
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
  const [userToken, setUserToken] = useState("")
  const [isAgreeDuplicate, setIsAgreeDuplicate] = useState(false)

  const stripe = useStripe();
  const elements = useElements();
  let pr = null
  const [values, setUser] = useState({
    email: "",
    sms_subscribe: "",
    first_name: "",
    last_name:"",
    address1: "",
    address2: "",
    city:"",
    province: "",
    zipcode: '',
    payment_method: null,
    paymentType: "",
    phoneNumber: '',
    ...(!!prescription.rightEye?.power && prescription.rightEye?.power !== "oneEye" &&
      {right_eye_power: prescription.rightEye.power}
    ),
    ...(!!prescription.leftEye?.power && prescription.leftEye?.power !== "oneEye" &&
      {left_eye_power: prescription.leftEye.power}
    ),
    doctor_id: doctor.id,
    line_items: [],
    cadence: "28 days",
    country: "US",
    order_number: "",
    variant_id: "",
    note: gclid,
    utm_campaign: utm_campaign_param,
    utm_content: utm_content,
    utm_source: utm_source,
    utm_medium: utm_medium,
    website_source: "base",
    hubbleProductID: "",
    prescriptions: [],
    price: 1,
    name: "Hubble Customer",
    url: "intake.hubblecontacts.com",
    taxes: taxes,
    ...initialValues
  })
  const isClassic = brand?.value === "hubble"
  const isHydro = brand?.value === "hydro"
  const canSubmit = submit_copy === "Place Order" && !isEmailValidationError

  const contactsType = () => {
    if (brand.title.toLowerCase().includes("hydro")) return "hydro"
    if (brand.title.toLowerCase().includes("skyhy")) return "skyhy"
    if (brand.value !== "hubble" && brand.value !== "hydro" && brand.value !== "skyhy") return "cc"

    return "classic"
  }

  const contactType = contactsType()
  const contactImages = {
    "hydro": "Pages/ContactsPCP/Hubble-Hydro-Lenses.png",
    "skyhy": "Pages/ContactsPCP/Hubble-Skyhy-Lenses.png",
    "classic": "Pages/ContactsPCP/Hubble-Classic-Lenses.png",
  }

  const ccImage = `Pages/Intake/Products/${brand?.image}.png`

  const contactsImage = contactImages[contactType] || ccImage

  const getLineItem = () => {
    const isCcProduct = contactsType() === "cc"
    const subPrice = contactsType() === "classic" && !brand.is_duplicate_item ? "1.00" : brand.sale_price

    if (!brandSelected) return null

    const item = {
      item_name: isCcProduct
        ? brand.title
        : lensesData[contactsType()]?.subName,
      currency: "USD",
      discount: 0,
      item_brand: isCcProduct ? brand.value : "Hubble",
      cadence: isCcProduct ? brand.cadence : 28,
      item_category: "contacts",
      quantity: isCcProduct ? 1 : 2,
      price: +subPrice * (isOneEye ? 1 : 2),
      prescription: prescription,
      line_item_type: "contacts",
      contacts_type: contactsType(),
      other_products: "",
      lens_quantity: isCcProduct ? brand.quantity : 30,
      product_id: isCcProduct
        ? brand.cc_product_id
        : lensesData[contactsType()]?.subProductId,
      variant_id: null,
    }

    return item
  }

  const [errors, setErrors] = useState({
    email: false,
    first_name: false,
    last_name:false,
    address1: false,
    city:false,
    province: false,
    zipcode: false,
    phone: false,
    paymentType: false,
  })

  // braintree things
  let PayPalButton = paypal.Button.driver('react', { React, ReactDOM });
  const [addresses, setAddresses] = useState([])

  const payment = (data, actions) => {
    const amount = parseFloat(total).toFixed(2)
    return actions.braintree.create({
      flow: 'vault',
      amount: amount,
      currency: 'USD'
    });
  }

  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 600)
}, [windowWidth])

  useEffect(() => {
    setUser({...values, doctor_id: doctor.id})
  }, [doctor]);

  useEffect(() => {
    if (shippingAddress.zipcode && shippingAddress.zipcode.length === 5 && zipRegEx.test(shippingAddress.zipcode)) {
      const isHydroOrSkyhy = brand?.value === 'hydro' || brand?.value === 'skyhy'
      const isClassicPaidShipping = brand?.value === 'hubble' && isPaidShipping
      const taxShipPrice = (isHydroOrSkyhy || isClassicPaidShipping || isCcProduct) ? 3.99 : 0;
      const tax_amount = (parseFloat(subTotal) + taxShipPrice).toFixed(2)
      const address = {
        ...shippingAddress,
        address_opt: shippingAddress.address2 || null,
        state: shippingAddress.province,
        zip: shippingAddress.zipcode
      }
      const tax_params = { ...address, subTotal: tax_amount }

      axios.post(`${process.env.GATSBY_API_URL}/api/v1/avalara/get_tax_rate`, { tax_params }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods":
          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }).then(response => {
          const tax_rate_amounts = response.data?.tax_rate_amounts
          if (+tax_params.subTotal === 1 && !isPaidShipping) {
            return setTaxes(0)
          }

          setTaxes(tax_rate_amounts?.toFixed(2))
        })

      if (typeof window !== "undefined") {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: "shipping_details",
          shipping_state: addresses.province,
          shipping_city: addresses.city,
        })
      }
    }
  }, [shippingAddress.zipcode, shippingAddress.province, brand, accessories, subTotal])

  const getSubscriptionDisclaimer = useCallback((isHubble, price, sale_price, percent_off, days) => {
    const isHydroOrSkyhy = brand?.value === 'hydro' || brand?.value === 'skyhy'
    let hubbleSubscription = `By completing your purchase of $1.00 for two weeks of Hubble contacts, you agree to sign up for a Hubble Subscription and to Hubble’s <a href="https://tos.hubblecontacts.com/" target="_blank">Terms of Service</a> and <a href="https://www.hubblecontacts.com/privacy-policy/" target="_blank">Privacy Policy</a>. Hubble will bill you $39.98 + $3.99 S+H plus tax every 28 days until you cancel or change the frequency of your shipments, which you can do at any time through our <a href="https://www.hubblecontacts.com/login" target="_blank">customer portal<a/> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>. Your subscription will begin at least 14 days after you complete your order (but may be slightly delayed if we need more time to contact your eye care provider).`
    let permissionToContact = "You are also giving us permission to contact your eye care provider regarding your order, which we are required to do if you do not provide us with a copy of your prescription. If we need to contact your eye care provider again in the future, we’ll tell you first."

    if (isIronclad){
      hubbleSubscription = `After you complete your order, Hubble will charge your payment method for the discounted total and then $${22.99 * (isOneEye ? 1 : 2)} + $3.99 S/H (plus tax where applicable) every 4 weeks until you modify or cancel your subscription, which you can do at any time through the <a href="https://www.hubblecontacts.com/login" target="_blank">customer portal<a/> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>.`
      permissionToContact = ""
    }
    if (isHubble) return `${hubbleSubscription} ${permissionToContact}`


    let hydroOrSkyhySubscription = `By completing this purchase you agree to sign up for a Hubble subscription and to Hubble’s <a href='https://tos.hubblecontacts.com/' target="_blank">Terms of Service</a> and <a href='https://www.hubblecontacts.com/privacy-policy' target="_blank">Privacy Policy<a/>. After your first discounted order, we will bill you $${price} + $3.99 S+H and tax every 28 days until you modify or cancel your subscription, which you can do at any time through our <a href='https://www.hubblecontacts.com/login' target="_blank">customer portal</a> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>. You will receive an order confirmation email with the details of your order and a link to track its progress. You are also giving us permission to contact your eye care provider regarding your order, which we are required to do if you do not provide us with a copy of your prescription. If we need to contact your eye care provider again in the future, we’ll tell you first.`
    if (isIronclad){
      hydroOrSkyhySubscription = `After you complete your order, Hubble will charge your payment method for the discounted total and then $${price} + $3.99 S+H (plus tax where applicable) every 28 days until you modify or cancel your subscription, which you can do at any time through the <a href='https://www.hubblecontacts.com/login' target="_blank">customer portal</a> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>.`
    }
    if (isHydroOrSkyhy) return hydroOrSkyhySubscription

    let contactsCartSubscription = 'By completing your purchase, you agree to sign up for a ContactsCart subscription.'

    if (percent_off) {
      contactsCartSubscription += ` We will bill you $${(sale_price).toFixed(2)} (after ${percent_off}% off) for your first order of contacts, then after ${days} days you will be billed $${price} for ${days} days of contacts, followed by $${price} every ${days} days (plus tax if charged in your state) until you cancel or update your subscription.`
    } else {
      contactsCartSubscription += ` We will bill you $${price} for ${days} days of subscription, followed by $${price} every ${days} days (plus tax if charged in your state) until you cancel or update your subscription.`
    }
    if (isIronclad){
      if (percent_off) {
        contactsCartSubscription = `After you complete your order, Hubble will charge your payment method for the discounted total and then $${price} (plus tax where applicable) every ${days} days until you modify or cancel your subscription, which you can do at any time through <a href="https://www.hubblecontacts.com/login" target="_blank">customer portal</a> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>.`
      } else {
        contactsCartSubscription = `After you complete your order, Hubble will charge your payment method for the discounted total and then $${price} (plus tax where applicable) every ${days} days until you modify or cancel your subscription, which you can do at any time through <a href="https://www.hubblecontacts.com/login" target="_blank">customer portal</a> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>.`
      }
    }
    return `${contactsCartSubscription} ${permissionToContact}`
  }, [brand, isOneEye])

  useEffect(() => {
    const {value} = brand
    const isHubble = value === 'hubble'
    const isHydroOrSkyhy = value === 'hydro' || value === 'skyhy'
    if (!stripe || (!isHubble && !taxes)) {
      // We can't create a PaymentRequest until Stripe.js loads.
      return;
    }
    let parsedTotal = parseFloat(subTotal)
    let stripeTotal =  Math.round(parsedTotal * 100)

    if (isHydroOrSkyhy || (isHubble && isPaidShipping)) {
      stripeTotal += 399
    }

    if (!isHubble || (isHubble && isPaidShipping)) {
      stripeTotal += (taxes * 100)
    }

    pr = stripe.paymentRequest({
      country: stripeCountry,
      currency: currency.toLowerCase(),
      total: {
        label: 'Hubble Contacts Trial',
        amount: stripeTotal,
      },
    });

    console.log('pr', pr)

    pr.on('paymentmethod', async (ev) => {
      setPaymentMethod(ev.paymentMethod.id)
      setMobilePay(true)
      ev.complete('success');
    })
    pr.canMakePayment().then((canMakePaymentRes) => {
      if (canMakePaymentRes) {
        setPaymentRequest(pr);
      }
    }).catch((e)=>{
      console.log(e)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, taxes]);

  const updatePaymentRequestInstance = async (event) => {
    const isHydroOrSkyhy = value === "hydro" || value === "skyhy"
    const isHubble = value === 'hubble'
    let parsedTotal = parseFloat(subTotal)
    let stripeTotal =  Math.round(parsedTotal * 100)

    if (isHydroOrSkyhy || (isHubble && isPaidShipping)) {
      stripeTotal += 399
    }

    if (!isHubble || (isHubble && isPaidShipping)) {
      stripeTotal += (taxes * 100)
    }

    await pr.update({
      total: {
        label: "Hubble Contacts Trial",
        amount: stripeTotal,
      },
    })
  }

  useEffect(() => {
    if (!brand || !prescription) return

    const { price, sale_price, value, days, percent_off, is_duplicate_item } = brand
    const tempIsHubble = value === 'hubble'
    const isHydroOrSkyhy = value === 'hydro' || value === 'skyhy'
    const accessoriesPrice = accessories?.reduce((acc, e) => acc + Number(e.price), 0) || 0
    const tempPrice = Number(sale_price) || Number(price)
    const tempQuantity = tempIsHubble && !isPaidShipping && !is_duplicate_item ? 1 : Object.values(prescription).filter(e => e.power !== "oneEye").length
    const shippingPrice = isHydroOrSkyhy || (tempIsHubble && isPaidShipping) || isCcProduct ? 3.99 : 0
    const tempTotal = tempPrice * tempQuantity + +taxes + accessoriesPrice
    const total = tempPrice * tempQuantity + shippingPrice + +taxes + accessoriesPrice
    const tempSubscriptionDisclaimer = getSubscriptionDisclaimer(
      tempIsHubble,
      Number(price) * tempQuantity,
      Number(sale_price) * tempQuantity + +taxes,
      percent_off,
      days,
    )

    setSubTotal((tempIsHubble && !is_duplicate_item ? tempPrice + accessoriesPrice : tempPrice * tempQuantity + accessoriesPrice).toFixed(2))
    setTotal(tempIsHubble && !isPaidShipping && !accessories?.length && !is_duplicate_item ? tempPrice.toFixed(2) : tempTotal.toFixed(2))
    setTotalWithShipmentAndTaxes(tempIsHubble && !isPaidShipping && !accessories?.length && !is_duplicate_item ? tempPrice.toFixed(2) : total.toFixed(2))
    setQuantity(tempIsHubble ? 1 : tempQuantity)
    setSubscriptionDisclaimer(tempSubscriptionDisclaimer)
    setIsCcProduct(brand.cc_product_id !== "hubble" && brand.cc_product_id !== "hydro" && brand.cc_product_id !== "skyhy" && brand.cc_product_id !== "classic")
    setUser({
      ...values,
      ...(!!prescription.leftEye?.power && prescription.leftEye?.power !== "oneEye" &&
        {left_eye_power: prescription.leftEye.power}
      ),
      ...(!!prescription.rightEye?.power && prescription.rightEye?.power !== "oneEye" &&
        {right_eye_power: prescription.rightEye.power}
      ),
      line_items: [getLineItem(), ...(accessories || [])].filter(Boolean),
      isPaidShipping,
    })
  }, [hubbleOrderContext, taxes, prescription, shippingAddress, brand, isPaidShipping, accessories, brandSelected])


  useEffect(() => {
    setUser({
      ...values,
      ...(!!prescription.leftEye?.power && prescription.leftEye?.power !== "oneEye" &&
        {left_eye_power: prescription.leftEye.power}
      ),
      ...(!!prescription.rightEye?.power && prescription.rightEye?.power !== "oneEye" &&
        {right_eye_power: prescription.rightEye.power}
      ),
      one_eye_order: (
        !prescription?.leftEye?.power ||
        prescription?.leftEye?.power === "oneEye" ||
        !prescription?.rightEye?.power ||
        prescription?.rightEye?.power === "oneEye"),
      line_items: [getLineItem(), ...(accessories || [])].filter(Boolean),
    })
  }, [prescription])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer.push({ecommerce: null})
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              index: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })

      window.dataLayer.push({ecommerce: null})
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: "",
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: '/N/A',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              quantity: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })
    }
    setUser((prevState) => ({
      ...prevState,
      paymentType: "stripe"
    }))
  },[])

  const onAuthorize = (payload, actions) =>  {
    setPaypallPaymentId(payload.nonce)
    setUser({...values, payment_method:  payload.nonce, paymentType: 'braintree'})
    setErrors((prevState) => ({
      ...prevState,
      paymentType: false
    }))
  }

  const handleInputChange = (e) => {
    const {name, value} =  e.target;
    if (name === 'email') {
      setErrors({...errors, [name]: false})
      const isEmailValid = isValidEmail(values.email)
      if (isEmailValid || !value) {
        setIsEmailValidationError(false)
      }
      if (!isEmailValid && !isFirstTimeEmailEdit) {
        setIsEmailValidationError(true)
      }
      if (value === '' && !isFirstTimeEmailEdit) {
        setErrors({...errors, [e.target.name]: true})
      }
    }
    if (name == "phone") {
      setUser({ ...values, [name]: value, phoneNumber: value })
      setShippingAddress({ ...shippingAddress, [name]: value, phoneNumber: value })
    } else {
      setUser({ ...values, [name]: value })
      setShippingAddress({ ...shippingAddress, [name]: value })
    }

    setEmojiErrors({...emojiErrors, [name]: false})
  }

  const handleRadioChange = (paymentType) => {

    if (paymentType === "stripe" && active == "stripe") {
      return setActive(null)
    }

    if (paymentType === "stripe") {
      setPaypallPaymentId(null)
      setActive(paymentType)
    }

    setUser((prevState) => ({
      ...prevState,
      paymentType
    }))
    setErrors((prevState) => ({
      ...prevState,
      paymentType: false
    }))
    // tiktokTrack('Registration')
  }

  const loqate = () => {
    // TODO: hook up int'l addresses

    var url = 'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws';
    var params = '';
        params += "&Key=" + encodeURIComponent("HG95-GT79-MD54-PP92");
        params += "&Text=" + encodeURIComponent(values.address1);
        params += "&Countries=" + encodeURIComponent("USA");
        params += "&IsMiddleware=" + encodeURIComponent(false);
        params += "&Limit=" + encodeURIComponent(10);
        params += "&Language=" + encodeURIComponent("en-gb");
    var http = new XMLHttpRequest();
    http.open('POST', url, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.onreadystatechange = function() {
      if(http.readyState == 4 && http.status == 200) {
          var response = JSON.parse(http.responseText);
          // Test for an error
          if (response.Items.length == 1 && typeof(response.Items[0].Error) != "undefined") {
            // Show the error message
            // alert(response.Items[0].Description);
          }
          else {
            // Check if there were any items found
            if (response.Items.length >= 0)
              setAddresses(response.Items)
          }
        }
      }
      http.send(params);
  }

  const klaviyoTrack = (e)  =>{
    const {name, value} =  e.target;

    if (typeof window !== "undefined") {
      (window)._learnq.push(['identify', {
        '$email' : value,
      }]);

      (window)._learnq.push(['track', 'Started Checkout', {
        "Items": [{
          "SKU": "",
          "Name": "Hubble Daily Contact Lenses",
          "Quantity": 1,
          "RowTotal": 0,
          "ProductURL": "https://hubble-prod.myshopify.com/products/hubble-daily-contact-lenses",
          "ImageURL": "https://cdn.shopify.com/s/files/1/1516/5228/products/Monthly_593863be-246f-49ea-8c69-273237b1c82d_large.jpg",
        }
      ]
      }]);
    }
  }
  // stripe payments

  const convertBase64toFriendlyBase64 = (str) => {
    const safe = str.replace(/\\+/g, '-').replace(/\//g, '_').replace(/\\=+$/, '');
    return encodeURIComponent(safe)
  }

  const encodeJSON = (json) => {
    const jsonAsString = JSON.stringify(json);
    return btoa(convertBase64toFriendlyBase64(jsonAsString));
  }

  const stripeSubmitCheckout = async (values, userToken) => {
    values.taxes = taxes
    const utm_params = search.replace("?", "")
    const headers = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }

    await axios.post(`${process.env.GATSBY_API_URL}/api/v1/stripe/checkout`, values, headers
    ).then((response) => {

    if (response.data.client_secret === "There was an error with your payment" || response.data.client_secret === undefined) {
      setCardErrors("There was an error with your payment" )
      setSubmitButtonText("Place Order")
      setSubmitCopy("Place Order")
      return
    }
    if (typeof window !== "undefined") {
      if (window.gtag !== null) {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: `${btoa(values?.email)}-${Date.now()}`,
            value: parseFloat(total),
            tax: 0,
            shipping: 0,
            currency: "USD",
            coupon: "N/A",
            paymentType: "stripe",
            items: [
              {
                item_id: brand.cc_product_id,
                item_name: brand.title,
                currency: "USD",
                discount: 0,
                item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
                item_category: "contacts",
                item_category2: 'spherical',
                item_category3: 'N/A', //gender
                item_category4: 'N/A',
                item_category5: 'N/A',
                item_list_id: 'N/A',
                item_variant: 'N/A',
                index: 1,
                price: parseFloat(brand.sale_price)
              }
            ]
          }
        });
      }
    }
    // setSubmitButtonText("Place Order")
    // setSubmitCopy("Place Order")

      const order_values = {
        email: values.email,
        order_number: response.data.order_number,
        name: `${values.first_name} ${values.last_name}`,
        address1: values.address1,
        city: values.city,
        province: values.province,
        zip: values.zipcode,
        country: stripeCountry,
        url: typeof window !== "undefined" ? window?.location?.href : null,
        price: 1,
        variantId: 19809451933769,
        contacts_cart_order: brand.cc_product_id !== "",
      }
      const encoded_values = encodeJSON(order_values)

      navigate(`/pages/thanks/?data=${encoded_values}&${utm_params}&order_value=1&order_currency=${currency}`)
      setTimeout(() => {
        setBrandSelected(false)
        setBrand({
          title: "",
          price: "",
          cc_product_id: "",
          sale_price: "",
          value: "",
          days: "",
          percent_off: ""
        })
        setTaxes(0)
        clearShippingAddress()
      }, 2000)
    }).catch((error) => {
      if (error.response?.data?.errors?.length && error.response.data.errors.some(e => e.includes("You already made a purchase of classic lenses with special price"))) {
        handleShowDuplicateDialog()
      } else if (error.response.data.errors?.length && error.response.data?.errors[0] === "discount already used by customer") {
        setCardErrors("This discount code has already been used")
      } else if (error.response.data.errors?.length && error.response?.data?.errors[0]) {
        setCardErrors(error.response.data.errors[0].slice(0, 1).toUpperCase() + error.response.data.errors[0].slice(1))
      } else {
        setCardErrors("There is an error with your payment")
      }

      setSubmitButtonText("Place Order")
      setSubmitCopy("Place Order")
    })
  }

  const stripeUserCreate =  async ( values ) => {
  const headers = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
  }
  await axios.post(`${process.env.GATSBY_API_URL}/api/v1/stripe/sign_up`, values, { headers })
  .then((response) => {
    const userToken = response.data.token
    setUserToken(userToken)

    const headers = {
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${userToken}`
      }
    }

    axios.get(`${process.env.GATSBY_API_URL}/api/v1/stripe/customers`, headers)
    .then(async (response) => {
      if (typeof window !== "undefined") {
        if (window.gtag !== null) {
          window.dataLayer.push({'user_id': response.data.id})
        }
      }

      if (isClassic && response.data?.special_discount_flags?.some(e => e.dup_flow) && !isAgreeDuplicate &&
        response.data.shipping.name === `${shippingAddress.first_name} ${shippingAddress.last_name}`) {
        const orders = await axios.get(`${process.env.GATSBY_API_URL}/api/v1/stripe/customers/orders`, headers)

        if (orders.data?.orders.find(e =>
          e.lenses.order_lens_type === "classic" &&
          !["error", "refunded"].includes(e.fulfillment_status.toLowerCase()) &&
          e.lenses.right_eye_power === (prescription?.rightEye?.power === "oneEye" ? undefined : prescription?.rightEye?.power) &&
          e.lenses.left_eye_power === (prescription?.leftEye?.power === "oneEye" ? undefined : prescription?.leftEye?.power))) {
          setSubmitButtonText("Place Order")
          setSubmitCopy("Place Order")
          handleShowDuplicateDialog()
          return
        }
      }

      values = { ...values, stripe_customer_id: response.data.id}
      stripeSubmitCheckout(values, userToken)
    })
    }).catch((reason) => {
      const responseErrors = reason?.response.data.errors

      if (responseErrors?.some(e => e.includes("emoji"))) {
        // setCardErrors("Emojis are not allowed")
        let _emojisError = emojiErrors
        if (responseErrors.find(e => e.includes("emoji") && e.includes("first_name"))) _emojisError = {..._emojisError, first_name: true}
        if (responseErrors.find(e => e.includes("emoji") && e.includes("last_name"))) _emojisError = {..._emojisError, last_name: true}
        if (responseErrors.find(e => e.includes("emoji") && e.includes("email"))) _emojisError= {..._emojisError, email: true}
        setEmojiErrors(_emojisError)
      } else {
        setCardErrors("There is an error with your payment")
      }
      setSubmitButtonText("Place Order")
      setSubmitCopy("Place Order")
    })
  }

  const submitUser = (values) => {
    const utm_params = search.replace('?', '')

    if (isPromo && values.line_items.some(({contacts_type: t}) => t === "skyhy" || t === "hydro"))
      values.isMemorial2024 = true

    console.log(values)

    if (isInvoice && initialValues?.stripe_customer_id) {
      stripeSubmitCheckout(values, process.env.GATSBY_ADMIN_TOKEN)
      return;
    }

    if (values.paymentType === "stripe" && (!isCcProduct || isInvoice && !initialValues?.stripe_customer_id)) {
      stripeUserCreate(values)
      return
    }


    if (!!paypallPaymentId) {
      values = { ...values, payment_method: paypallPaymentId, paymentType: "braintree"}
    }

    let checkout_url = `${process.env.GATSBY_API_URL}/stripe/create-combo-charge`
    if (isCcProduct) {
      checkout_url = `${process.env.GATSBY_API_URL}/stripe/cc-create-charge`
    }

    values.taxes = taxes
    axios.post(checkout_url,values, { headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }}).then((response) => {
      if ( response.data.order_number == "There was an error with your payment" || response.data.order_number === undefined) {
        setCardErrors("There was an error with your payment" )
        setSubmitButtonText("Place Order")
        setSubmitCopy("Place Order")
        return
      }
      const order_values =   {
        email: values.email ,
        order_number: response.data.order_number ,
        name: `${values.first_name} ${values.last_name}`,
        address1: values.address1 ,
        city: values.city,
        province: values.province ,
        zip: values.zipcode,
        country: stripeCountry,
        url: typeof window !== "undefined" ? window?.location?.href : null,
        price: 1 ,
        variantId: 19809451933769,
        contacts_cart_order: brand.cc_product_id !== ""
      }
      if (typeof window !== "undefined") {
        window.dataLayer.push({ecommerce: null})
        window.dataLayer.push({
          event: "add_payment_info",
          ecommerce: {
          paymentType: "stripe",
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              index: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })

      window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          paymentType: "stripe",
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              index: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })

      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          coupon: "",
          currency: 'USD',
          shipping: 0,
          tax: 0,
          transaction_id: `${btoa(values?.email)}-${Date.now()}`,
          website_source: "intake-hubble-lp",
          value: parseFloat(total),
          paymentType: "stripe",
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              index: 1,
              item_variant: 'N/A',
              quantity: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })
    }

      const encoded_values=encodeJSON(order_values)
      // setSubmitButtonText("Place Order")
      // setSubmitCopy("Place Order")
      navigate(`/pages/thanks/?data=${encoded_values}&${utm_params}&order_value=1&order_currency=${currency}`)
      setTimeout(() => {
        setTaxes(0)
        clearShippingAddress()
      }, 2000)
    }).catch(()=>{
      setCardErrors("There was a problem with your payment")
      setSubmitButtonText('Place Order')
      setSubmitCopy("Place Order")
    })
  }

  const autoFillAddress = (address) => {
    let splitCityZip = address.Description.match(/[\d\.]+|\D+/g);
    let addressCity = splitCityZip[0].split(" ")
    addressCity.pop()
    let state = addressCity[addressCity.length - 1]
    addressCity.pop()
    let city =  addressCity.join(" ")
    setAddresses([])
    setUser({ ...values, address1: address.Text, zipcode: splitCityZip[1], city: city, province: state })
    setShippingAddress({
      first_name : values.first_name,
      last_name: values.last_name,
      address: address.Text,
      city: city,
      province: state,
      zipcode: splitCityZip[1],
    })
  }


  const handleClickWrap = () => {
    setIronclad(ironclad === "" ? "ironclad" : "")
    setIronCladWarining(false)
    if (window !== undefined) {
      window._ps("set", "signer_id", values?.email);
      window._ps("send", "updated", { custom_data: { first_name: values?.first_name , values: values?.last_name } });
      window._ps("mainwebsite-tos:send", "agreed", {
        // The event_callback function will be invoked once the "send" is complete.
        event_callback: function(err, eventType, clickwrapGroup, payload) {
          if (err) {
            // The send encountered an error.
          }
          // setCheckoutButtonDisabled(false)
          // The send is complete and acceptance was captured successfully.
        }
      });
    }
  }


  // for mobile pay
  useEffect(() => {
    if (paymentMethod !== null && isMobilePay) {
      setUser({...values, payment_method: paymentMethod, paymentType: "stripe"})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobilePay]);

  useEffect(() => {
    if (values.paymentType === "stripe" && isMobilePay === false && !initialValues?.stripe_customer_id) {
      submitUser(values)
    }
    if (values.paymentType === "stripe" && isMobilePay){
      handleSubmit(new Event('build'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payment_method]);

  const handleSubmit = async (event) => {
    if (doctor.isNew && !doctor.id) {
      if (Object.values(doctor).some(value => !value)) {
        setRequiredError(true)
        steps.find(step => step.title === "Doctor")?.ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
        return;
      }

      const newDoctor = (await axios.post(`${process.env.GATSBY_ADMIN_DOC_URL}/v1/docs.json`, doctor, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })).data.data?.doc

      setDoctor({
        ...doctor,
        id: newDoctor.id,
      })
      doctor.id = newDoctor.id
      setUser({...values, doctor_id: newDoctor.id})
      values.doctor_id = newDoctor.id
    }

    if (isInvoice) {
      if (!getLineItem() || !brandSelected) {
        setRequiredError(true)
        steps.find(step => step.title === "Brand")?.ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
        return;
      } else if (Object.values(prescription).some(eye => Object.values(eye).some(value => !value))) {
        setRequiredError(true)
        steps.find(step => step.title === "Rx")?.ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
        return;
      } else if (!doctor.id) {
        setRequiredError(true)
        steps.find(step => step.title === "Doctor")?.ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
        return;
      }
    }

    if(isIronclad && ironclad === ""){
      setIronCladWarining(true)
      return
    }
    if ( values.address1.includes("1721 Flowers")){
      return false
    }

    const isZipInvalid = shippingAddress?.zipcode && !zipRegEx.test(shippingAddress.zipcode)
    // Block native form submission.
    if (Object.values(errors).some(error => error) || isEmailValidationError || isZipInvalid) {
      return false;
    }

    const tempErrors = Object.keys(errors).reduce((result, key) => {
      if (key === "phone" && isInvoice && initialValues.stripe_customer_id) return result
      if (!values[key]) return { ...result, [key]: true }

      return result
    }, {})

    if (Object.keys(tempErrors).length) {
      return setErrors(errors => ({
        ...errors,
        ...tempErrors
      }))
    }

    event.preventDefault();
    setSubmitButtonText('Submitting')
    setSubmitCopy("Submitting");

    if (isInvoice && initialValues?.stripe_customer_id) {
      submitUser(values)
    }

    if (values.paymentType === "stripe" && isMobilePay === false) {
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const responseToken = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      if ( responseToken?.paymentMethod?.card.funding == "prepaid") {
        setSubmitButtonText("Place Order")
        setSubmitCopy("Place Order")
        setCardErrors("Oops! We’re sorry, looks like we can’t complete that transaction. Please note: Hubble does not accept prepaid cards.")
        ReactGA.event({
          category: 'User',
          action: 'Attempted to use a prepaid card'
        });

        return false;
      }

      // setSubmitCopy("Place Order")
      if (responseToken.paymentMethod){
        setUser({...values, payment_method: await responseToken.paymentMethod?.id })
      }

      if (responseToken?.error) {
        setCardErrors(responseToken.error?.message)
        setSubmitButtonText('Place Order')
        setSubmitCopy("Place Order")
      }
    }
    else {
      submitUser(values)
    }
  };

  const validateField = (e) => {
    if (e.target.name === "phone" && isInvoice && initialValues.stripe_customer_id) return
    if (e.target.name === 'email') {
      const isEmailValid = isValidEmail(values.email)
      setIsEmailValidationError(!isEmailValid)
      !e.target.value && setIsEmailValidationError(false)
    }
    e.target.value === "" ? setErrors({...errors, [e.target.name]: true}) : setErrors({...errors, [e.target.name]: false})
  }

  const handleRemoveAccessory = ({product_id}) => {
    const i = accessories.findIndex(e => e.product_id === product_id)
    const newAccessories = accessories.toSpliced(i, 1)
    setAccessories(newAccessories)
  }

  const handleShowDuplicateDialog = () => {
    setShowDuplicateDialog(true)

    window.dataLayer.push({
      event: "dupe_flow_initiated",
    })
  }

  const handleHideDuplicateDialog = () => {
    const newAddress = {
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      zipcode: "",
      phone: "",
    }

    setShippingAddress(newAddress)
    setUser({...values, ...newAddress})
    setCardErrors("")
    setShowDuplicateDialog(false)
    setUserToken("")
    setMobilePay(false)
    setPaypallPaymentId(null)
    setPaymentMethod(null)
  }

  const handleSubmitDuplicateDialog = () => {
    const optionsValues = quantityOptionsValues.classic
    const duplicatePrice = getValues(optionsValues, brand.quantity, "duplicatePrice")

    const newContactsItem = {
      ...brand,
      sale_price: duplicatePrice,
      is_duplicate_item: true,
    }

    setBrand(newContactsItem)
    setCardErrors("")
    setShowDuplicateDialog(false)
    setIsAgreeDuplicate(true)

    window.dataLayer.push({
      event: "dupe_accepted",
    })
  }

  useEffect(() => {
    if (isAgreeDuplicate) handleSubmit(new Event(""))
  }, [isAgreeDuplicate]);

  const OrderSummaryContent = () => (
    <div className="order-summary">
      <div className="order-title mb-2">Order Summary</div>
      <div className="checkout-img-container">
        {brand && <>
        <div className="checkout-img">
          <WebpImage objectFit="contain" fileName={brand.isAccessory ? `Pages/Intake/Products/${brand.image}.png` : contactsImage} />
        </div>
        {isMobile && <span className="name bold">{brand?.title}</span>}
        </>}
      </div>
      <div className="order-info mt-4">
        <div className={isHydroSkyhy ? "text-block-hydro-skyhy" : "text-block"}>
          {brand.value && <>
          {
            !isMobile &&
            <div className="product mb-3">
              <span className="name bold">{brand?.title}</span>
            </div>
          }

          <p className="lense-details">
            <span className="name">{brand?.quantity || 30} {isOneEye ? "Lenses" : "Pairs"}</span>
            <span className={`crossed-price bold ${isAvg ? 'avg' : ''}`}>${price}{!isOneEye ? "/eye" : ""}</span>
          </p>
          <p className="lense-details">
            <span className="name">{isClassic && !brand.is_duplicate_item ? `${hubbleClassic.trialPeriod}-day starter pack` : 'Your first order'} </span>
            <div className="price bold">
              <div>${isClassic && !brand.is_duplicate_item ? brand?.sale_price : `${brand?.sale_price}${!isOneEye ? "/eye" : ""}`}</div>
              {!isClassic && <div>{isHydro ? "80" : isCcProduct ? brand.percent_off : "66"}% OFF</div>}
            </div>
          </p>
          <p className="recurring-notice mt-4">
            {
              isClassic ?
                `After your ${brand.is_duplicate_item ? `30 ${isOneEye ? "lenses" : "pairs"}` : `${hubbleClassic.trialPeriod}-day starter pack`}, recurring orders will be $22.99${!isOneEye ? "/eye" : ""}.` :
                `After your first ${brand?.quantity || 30} ${isOneEye ? "lenses" : "pairs"}, recurring orders will be $${price}${!isOneEye ? "/eye" : ""}.`
            }
          </p>
          </>}
          {accessories.map(item => <AccessoryItem {...item} removeItem={() => handleRemoveAccessory(item)}/>)}
          <p className="line-item mt-3">
            <span className="name">Subtotal</span>
            <span className="price">${subTotal}</span>
          </p>
          <p className="line-item">
            <span className="name">Shipping</span>
            <span className="price">{isClassic && !isPaidShipping ?  "Free" : shipping}</span>
          </p>
          <p className="line-item">
            <span className="name">Taxes</span>
            <span className="price">${brand.value === "hubble" && !brand.is_duplicate_item && !isPaidShipping && accessories.length === 0 ? "0.00" : taxes || "0.00"}</span>
          </p>
          <p className="line-item last">
            <span className="name bold">Total</span>
            <span className="price bold">${(isHydroSkyhy || (isClassic && isPaidShipping && accessories.length !== 0) || isCcProduct) ? totalWithShipmentAndTaxes : total}</span>
          </p>
        </div>
      </div>
    </div>
  )

  useEffect(()=> {
    brand?.value === 'hydro' || brand?.value === 'skyhy' || (brand?.value === 'hubble' && isPaidShipping) || isCcProduct ? setShipping('$3.99') : setShipping('$0.00')
  }, [hubbleOrderContext, isPaidShipping])

  const { price, value } = brand

  return (
    <StepWrapper
      title=''
      steps={steps}
      currentStep='Checkout'
      className='checkout v3'
      isV3
      isAvg={isAvg}
    >
      <div className="checkout-container">
        <div className="checkout-grid">
          <div className="checkout-form">
            <div className="order-title">Contact Information</div>
            <div className="form-label">Email</div>
            <input className="form-input"  type="email" name="email" onBlur={(e) => { klaviyoTrack(e); validateField(e); setIsFirstTimeEmailEdit(false)  }} required={true} value={values.email} onChange={(e) => handleInputChange(e)} placeholder="Enter your email" />
            { errors.email
            ? <p className="error">Email is required</p>
            : isEmailValidationError
            ? <p className="error">Email is invalid</p>
            : emojiErrors.email
            ? <p className="error">Emojis are not allowed</p>
            : null
            }
            <div className="space-40" />
            {(isInvoice && !initialValues?.stripe_customer_id || !isInvoice) ? <>
            <div className="order-title">Shipping Address</div>
            <div className="grid-2">
              <div>
                <div className="form-label">First Name</div>
                <input className="form-input" type="text" onBlur={(e)=> validateField(e) } required placeholder="Enter your first name" name="first_name" value={values.first_name} onChange={(e) => handleInputChange(e)}/>
                { errors.first_name
                  ? <p className="error">First name is required</p>
                  : emojiErrors.first_name
                  ? <p className="error">Emojis are not allowed</p>
                  : null }
              </div>
              <div>
                <div className="form-label">Last Name</div>
                <input className="form-input" type="text"  onBlur={(e)=> validateField(e) }  required={true} placeholder="Enter your last name" name="last_name" value={values.last_name}  onChange={(e) => handleInputChange(e)}/>
                { errors.last_name
                  ? <p className="error">Last name is required</p>
                  : emojiErrors.last_name
                  ? <p className="error">Emojis are not allowed</p>
                  : null }
              </div>
            </div>
            <div className="space-24" />
            <div className="form-group-columns address">
              <div className="form-group">
                <div className="form-label">Address</div>
                <input
                  className="form-input"
                  type="text"
                  onBlur={(e)=> {
                    validateField(e);
                    values.address1 === "" && setAddresses([]);
                    setTimeout(() => setIsAddressListShown(false), 100)
                    }}
                  required={true}
                  placeholder="Enter your address"
                  name="address1"
                  value={values.address1}
                  onChange={(e) => {
                    !isAddressListShown && setIsAddressListShown(true)
                    handleInputChange(e);
                    loqate()
                    }
                  }
                />
                { errors.address1 && <p className="error">Address is required</p> }
                { addresses.length >= 1 && values.address1.length >=1 && isAddressListShown &&
                  <div className="address-dropdown">
                    {addresses.map((address) => {
                      return <p className="address" onClick={() => autoFillAddress(address)} ><strong>{address.Text}</strong> {address.Description}</p>
                    })}
                  </div>
                }
              </div>
              <div className="space-24" />
              <div className="form-group">
                <input
                  className="form-input"
                  type="text"
                  placeholder="Apartment, suite, etc (optional)"
                  name="address2"
                  value={values.address2}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="space-24" />
            <div className="grid-3">
              <div>
                <div className="form-label">City</div>
                <input className="form-input" type="text" onBlur={(e)=> validateField(e) } required={true} placeholder="City" name="city" value={values.city}  onChange={(e) => handleInputChange(e)}/>
                { errors.city && <p className="error">City is required</p> }
              </div>
              <div>
                <div className="form-label">State</div>
                <CheckoutSelect
                  name="province"
                  value={states.find(option => option.value === values.province) || null}
                  onChange={({value}) => {
                    const e = {target : {name: 'province', value}}
                    handleInputChange(e)
                    validateField(e)
                  }}
                  options={states}
                  filterOption={({ label }, input) => {
                    if (!input) return true
                    return label.toLowerCase().startsWith(input.toLowerCase())
                  }}
                  />
                { errors.province && <p className="error">State is required</p> }
              </div>
              <div>
                <div className="form-label">Zip Code</div>
                <input className="form-input " type="text" onBlur={(e)=> validateField(e) } required={true} placeholder="Zip code" name="zipcode" value={values.zipcode}  onChange={(e) => handleInputChange(e)}/>
                { errors.zipcode
                  ? <p className="error">Zip is required</p>
                  : shippingAddress && (shippingAddress.zipcode && !zipRegEx.test(shippingAddress.zipcode))
                  ? <p className="error">Invalid zip code</p>
                  : ""
                }
              </div>
            </div>
            <div className="space-24" />
            <div className="form-label">Phone</div>
            <input className="form-input" type="text" onBlur={(e)=> validateField(e) }  placeholder={"Enter your phone number" } name="phone" required={true} value={values.phone}  onChange={(e) => handleInputChange(e)}/>
            { errors.phone &&  <p className="error">Your phone number is required in order for us to verify your prescription.</p> }
            <div className="space-40" />
            { isMobile && OrderSummaryContent() }

            <div className="order-title">Payment Method</div>
            <div className="payment-methods">
              <div className="radio-container">
                <div className="checkout-radio">
                  <div className="radio-head payment-method-wrapper">
                    <span className="name text h8 neutral">Credit/Debit Card</span>
                    <WebpImage className="payment-logo" fileName="Pages/Checkout/cards.svg" alt="credit cards"/>
                  </div>
                  <div className="radio-body">
                    <CardElement/>
                  </div>
                </div>
                <div className="space-24" />
                {!isInvoice &&
                  <PayPalButton
                    braintree={ braintree }
                    commit={true }
                    env={ process.env.REACT_APP_BRAINTREE_ENV }
                    client={ {sandbox: 'sandbox_tvynb2kv_jt9yb92tffv8pprd', production: 'production_9qpr3jqc_qvfxwnzxr53brmt8' }}
                    payment={ (data, actions) => payment(data, actions) }
                    onAuthorize={ (data, actions) => onAuthorize(data, actions) }
                    style={{ shape: 'rect', tagline: false, label: 'paypal', }}
                  />
                }
                {paymentRequest && (
                  <div>
                    <div className="space-24" />
                    <PaymentRequestButtonElement
                      className="mobile-pay"
                      options={{
                        paymentRequest,
                        style: {
                          paymentRequestButton: {
                            height: isMobile ? '45px' : '55px',
                          },
                        }
                      }}
                      onClick={updatePaymentRequestInstance}
                    />
                  </div>
                )}
              </div>
            </div>
            </> : <>
            { isMobile && OrderSummaryContent() }
            </>}
            { Object.values(emojiErrors).some(e => e === true)
              ? <div className="error mb-3">Emojis are not allowed</div>
              : cardErrors && <div className="error mb-3">{cardErrors}</div> }
            <div className="customer-info">
              <div className="payment-block">
                <div className="disclaimer-block">
                  {brand &&
                    <p className="subscription" dangerouslySetInnerHTML={{ __html: subscriptionDisclaimer }} />
                  }
                  <div className={`ironclad-wrapper mt-2 subscription ${ironcladWarning ? "warning" : ""}`} >
                    <PrescriptionRadio
                      value="ironclad"
                      label={`I agree to sign up for a Hubble subscription and to <a href="https://tos.hubblecontacts.com/" target="_blank">Hubble’s Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`}
                      onClick={() => handleClickWrap()}
                      active={ironclad}
                    />
                  </div>
                </div>

                <NewButton
                  className="submit-button outside-block"
                  type="button"
                  disabled={!canSubmit}
                  label={submit_copy}
                  onClick={(e) => {
                    if (canSubmit) {
                      handleSubmit(e)
                      // tiktokTrack('AddBilling')
                    }
                  }}
                />

                {/* {isPaymentError && <div className="error-block">{errorCopy}</div>} */}
              </div>
            </div>
          </div>
          { !isMobile && OrderSummaryContent() }
        </div>
      </div>

      <CheckoutDuplicateModal
        show={showDuplicateDialog}
        onHide={handleHideDuplicateDialog}
        onSubmit={handleSubmitDuplicateDialog}
      />
    </StepWrapper>
  );
};

export const AccessoryItem = ({
  image,
  item_name,
  price,
  colorTitle,
  removeItem,
}) => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 578)
  }, [windowWidth])

  return (
    <div className="item-wrapper">
      <div className="top-content">
        <div className={`image-container ${!isMobile ? "new-rx-desktop" : ""}`}>
          <WebpImage objectFit="contain" fileName={image}/>
        </div>
        <div className="text-container">
          <p className="title text h7">{item_name}</p>
          <p className="price-info text h10 granite">{colorTitle}</p>
        </div>
        {!isMobile && <p className="price desktop">${price}</p>}
      </div>
      <div className="info-price-wrapper">
        <a className="remove text h8 neutral" onClick={removeItem}>Remove</a>
        {isMobile && <p className="price desktop">${price}</p>}
      </div>
    </div>
  )
}

export default CheckoutV3;
