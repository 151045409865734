import axios from "axios";

export default function tiktokTrack(event) {
  if (typeof window !== "undefined") {
    if (window.ttq !== null) {
      axios.post(`${process.env.REACT_APP_API_URL}/api/tiktok_postback`,{
        pixel: 'C3S2JG7M75MTE2LNFGH0',
        event: event,
      },
      { headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Credentials':true,
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }}
      )
    }
  }
}
