import WebpImage from "../../WebpImage"
import React, { useState, useContext, useEffect, useRef } from "react"
import Select from "react-select"

import HubbleOrderContext from "../../../context/hubbleOrderContext"
import StepWrapper from "../StepWrapper"
import { productInfo } from "./mockedData"
import "./styles.scss"
import useTest from "../../../utils/useTest";

const Prescription = ({ steps, setActiveSteps, isV3, isAvg, isInvoice, initialValues }) => {
  const { brand, prescription, setPrescription, requiredError } = useContext(
    HubbleOrderContext
  )
  const [productPrescription, setProductPrescription] = useState(
    prescription || {
      leftEye: {
        power: null,
      },
      rightEye: {
        power: null,
      },
    }
  )
  const [isError, setIsError] = useState(false)
  const oneEyeTest = useTest("oneEyeTest")
  const leftPowerRef = useRef(null)
  const rightPowerRef = useRef(null)

  const options = productInfo[brand.cc_product_id] || productInfo.default

  const handleSelect = (eye, option, e) => {
    setProductPrescription(prevState => ({
      ...prevState,
      [eye]: {
        ...prevState[eye],
        [option]: e,
        ...(e.value === "oneEye" && options.base_curve_values?.length > 1 && {baseCurve: ""}),
        ...(e.value === "oneEye" && options.add_power?.length > 1 && {addPower: ""}),
        ...(prevState[eye].axis && e.value === "oneEye" && {axis: "", cylinder: ""}),
      },
    }))

    setPrescription(prevState => ({
      ...prevState,
      [eye]: {
        ...prevState[eye],
        [option]: e.value,
        ...(e.value === "oneEye" && options.base_curve_values?.length > 1 && {baseCurve: ""}),
        ...(e.value === "oneEye" && options.add_power?.length > 1 && {addPower: ""}),
        ...(prevState[eye].axis && e.value === "oneEye" && {axis: "", cylinder: ""}),
      },
    }))
  }

  const handleSubmit = () => {
    setIsError(false)

    if (
      productPrescription.leftEye.power.value === "oneEye" && productPrescription.rightEye.power.value === "oneEye" ||
      Object.values(productPrescription).find(eye =>
        eye.power.value !== "oneEye" &&
        Object.values(eye).some(value => !value)
      )
    ) {
      setActiveSteps({ Doctor: false, Checkout: false })
      return setIsError(true)
    }

    const newPrescription = Object.entries(productPrescription).reduce(
      (result, [eye, values]) => ({
        ...result,
        [eye]: Object.entries(values).reduce(
          (eye_result, [key, value]) => ({
            ...eye_result,
            ...(value.value && {[key]: value.value}),
          }),
          {}
        ),
      }),
      {}
    )

    setActiveSteps({ Doctor: true })
    setPrescription(newPrescription)
    setTimeout(() => {
      if (isInvoice) return
      const currentRef = steps.find(({ title }) => title === "Doctor")
      currentRef.ref.current.scrollIntoView({ behavior: "smooth" })
    }, 200)

    if (typeof window !== "undefined") {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "prescription",
        ecommerce: {
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: "",
              item_brand: brand.title.includes("Hubble")
                ? "Hubble"
                : brand.title,
              item_category: "contacts",
              item_category2: "/N/A",
              item_category3: "N/A", //gender
              item_category4: "N/A",
              item_category5: "N/A",
              item_list_id: "N/A",
              item_variant: "N/A",
              quantity: 1,
              price: parseFloat(brand.sale_price),
            },
          ],
        },
        left_eye: newPrescription?.leftEye?.power?.value,
        left_cylinder: newPrescription?.leftEye?.cylinder?.value,
        left_base_curve: newPrescription?.leftEye?.baseCurve?.value,
        left_diameter: newPrescription?.leftEye?.diameter?.value,
        right_eye: newPrescription?.rightEye?.power?.value,
        right_cylinder: newPrescription?.rightEye?.cylinder?.value,
        right_base_curve: newPrescription?.rightEye?.baseCurve?.value,
        right_diameter: newPrescription?.rightEye?.diameter?.value,
      })
    }
  }

  useEffect(() => {
    if (!brand) return

    setProductPrescription({
      leftEye: {
        power: "",
      },
      rightEye: {
        power: "",
      },
    })
    setActiveSteps({ Doctor: false, Checkout: false })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand.value])

  useEffect(() => {
    setPrescription(productPrescription)

    if (
      Object.values(productPrescription).find(eye =>
        Object.values(eye).every(value => value)
      )
    ) {
      handleSubmit()
      setIsError(false)
    }
  }, [productPrescription])

  useEffect(() => {
    if (initialValues) {
      const newPrescription = {
        leftEye: {
          power: options?.eye_powers.find(e => e.value === initialValues.leftEye.power),
          ...(inputOptions.find(e => e[0] === "add_power") && {addPower: options?.add_power?.find(e => e.value === initialValues.leftEye.addPower)}),
          ...(inputOptions.find(e => e[0] === "cylinder") && {cylinder: options?.cylinder?.find(e => e.value === initialValues.leftEye.cylinder)}),
          ...(inputOptions.find(e => e[0] === "axis") && {axis: options?.axis?.find(e => e.value === initialValues.leftEye.axis)}),
        },
        rightEye: {
          power: options?.eye_powers.find(e => e.value === initialValues.rightEye.power),
          ...(inputOptions.find(e => e[0] === "add_power") && {addPower: options?.add_power?.find(e => e.value === initialValues.rightEye.addPower)}),
          ...(inputOptions.find(e => e[0] === "cylinder") && {cylinder: options?.cylinder?.find(e => e.value === initialValues.rightEye.cylinder)}),
          ...(inputOptions.find(e => e[0] === "axis") && {axis: options?.axis?.find(e => e.value === initialValues.rightEye.axis)}),
        },
      }

      setPrescription(newPrescription)
      setProductPrescription(newPrescription)
    }
  }, [initialValues]);

  const isHubbleProduct = brand?.value === 'skyhy' || brand?.value === 'hubble' || brand?.value === 'hydro'
  const inputOptions = options && (isHubbleProduct ? Object.entries(options).slice(0, 1) : Object.entries(options))

  return (
    <StepWrapper
      title={isV3 ? "Enter Your Prescription" : "Enter prescription info"}
      steps={steps}
      currentStep="Rx"
      className="prescription"
      onSubmit={handleSubmit}
      isV3={isV3}
      isAvg={isAvg}
    >
      {isV3 ? (
        <>
          <div className="prescription-container-v3">
            {options &&
              inputOptions
                .map(([key, value], index) => {
                  let label = ""
                  let option = ""

                  switch (key) {
                    case "base_curve_values":
                      label = "Base Curve"
                      option = "baseCurve"
                      break

                    case "eye_powers":
                      label = "Power"
                      option = "power"
                      break

                    case "add_power":
                      label = "Add Power"
                      option = "addPower"
                      break

                    default:
                      label = key.charAt(0).toUpperCase() + key.slice(1)
                      option = key
                      break
                  }

                  if (
                    (typeof value === "string" &&
                      !productPrescription.leftEye[option]) ||
                    productPrescription.leftEye[option] === undefined
                  ) {
                    let optionValue

                    if (typeof value === "string") {
                      optionValue = { value, label: value }
                    } else if (value.length === 1) {
                      if (value[0].value) {
                        optionValue = value[0]
                      } else {
                        optionValue = { value: value[0], label: value[0] }
                      }
                    } else {
                      optionValue = ""
                    }

                    const newPrescription = {
                      leftEye: {
                        ...productPrescription.leftEye,
                        [option]: optionValue,
                      },
                      rightEye: {
                        ...productPrescription.rightEye,
                        [option]: optionValue,
                      },
                    }

                    setProductPrescription(newPrescription)
                  }

                  return (
                    <div
                      className={`spec-container ${
                        isV3 ? "spec-container-v3" : ""
                      }`}
                      key={index}
                    >
                      <div className="select-container-v3">
                        <label>{`Right Eye ${label}${label === 'Power' ? ' (OD)' : ''}`}</label>
                        <Select
                          className={`hubble-select prescription v3-select ${
                            (isError || requiredError) && !productPrescription.rightEye[option] &&
                            productPrescription.rightEye.power.value !== "oneEye"
                              ? "error"
                              : ""
                          }`.trim()}
                          ref={option === "power" ? rightPowerRef : null}
                          classNamePrefix="HubbleSelect"
                          value={productPrescription.rightEye[option]}
                          onChange={e => handleSelect("rightEye", option, e)}
                          placeholder="Select"
                          options={option === "power" && oneEyeTest
                            ? [{ value: 'oneEye', label: `I don't need right eye lenses` }, ...value]
                            : value}
                          isDisabled={
                            typeof value === "string" || value.length === 1 ||
                            option !== "power" && productPrescription.rightEye.power.value === "oneEye"
                          }
                          onMenuOpen={() => {
                            if (option !== "power") return

                            setTimeout(() => {
                              rightPowerRef.current.scrollToFocusedOptionOnUpdate = true
                              rightPowerRef.current.setState({
                                focusedOption: value.find(
                                  opt => opt.value === (oneEyeTest ? "oneEye" : "-2.00")
                                ),
                              })
                            }, 0)
                          }}
                        />
                      </div>
                      <div className="select-container-v3">
                        <label>{`Left Eye ${label}${label === 'Power' ? ' (OS)' : ''}`}</label>
                        <Select
                          className={`hubble-select prescription v3-select ${
                            (isError || requiredError) && !productPrescription.leftEye[option] &&
                            productPrescription.leftEye.power.value !== "oneEye"
                              ? "error"
                              : ""
                          }`.trim()}
                          ref={option === "power" ? leftPowerRef : null}
                          classNamePrefix="HubbleSelect"
                          value={productPrescription.leftEye[option]}
                          onChange={e => handleSelect("leftEye", option, e)}
                          placeholder="Select"
                          isSearchable={false}
                          options={option === "power" && oneEyeTest
                            ? [{ value: 'oneEye', label: `I don't need left eye lenses` }, ...value]
                            : value}
                          isDisabled={
                            typeof value === "string" || value.length === 1 ||
                            option !== "power" && productPrescription.leftEye.power.value === "oneEye"
                          }
                          onMenuOpen={() => {
                            if (option !== "power") return
                            setTimeout(() => {
                              leftPowerRef.current.scrollToFocusedOptionOnUpdate = true
                              leftPowerRef.current.setState({
                                focusedOption: value.find(
                                  opt => opt.value === (oneEyeTest ? "oneEye" : "-2.00")
                                ),
                              })
                            }, 0)
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
            {brand?.value === "hubble" && (
              <p className="prescription-info">
                Hubble lenses have a base curve of 8.6 and a diameter of 14.2.
                <br />
                They are made of methafilcon A and manufactured by St. Shine
                Optical Co.
                <br />
                <br />
              </p>
            )}
            {brand?.value === "hydro" && (
              <p className="prescription-info">
                Hydro by Hubble contact lenses have a base curve of 8.4 and a
                diameter of 14.2. They are made of Hioxifilcon A and manufactured
                by Menicon Co.
              </p>
            )}
            {brand?.value === "skyhy" && (
              <p className="prescription-info">
                SkyHy by Hubble contact lenses have a base curve of 8.7 and a
                diameter of 14.1. They are made of Olifilcon B and manufactured by
                Visco Vision Inc.
              </p>
            )}
            {!["hubble", "hydro", "skyhy"].some(e => e === brand?.value) && (
              <p className="prescription-info">{brand?.description}</p>
            )}
            <p className="prescription-info">
              Please make sure all of this information matches what’s on your
              prescription.
              <br />
              <br />
              {!isInvoice && <>
              You may email your prescription to{" "}
              <span>prescriptions@hubblecontacts.com</span> after you checkout.
              Please include your order number.
              </>}
            </p>
          </div>
        </>
      ) : (
        <>
          <p>
            You can find your prescription info on the side of your contacts
            box.
          </p>
          <p>
            You may also email your prescription to
            prescriptions@hubblecontacts.com. Please send you prescription from
            the email account you use to checkout and/or include your order
            number.
          </p>
          <div className="prescription-container">
            {options &&
              Object.entries(options).map(([key, value], index) => {
                let label = ""
                let option = ""

                switch (key) {
                  case "base_curve_values":
                    label = "Base Curve"
                    option = "baseCurve"
                    break

                  case "eye_powers":
                    label = "Power"
                    option = "power"
                    break

                  case "add_power":
                    label = "Add Power"
                    option = "addPower"
                    break

                  default:
                    label = key.charAt(0).toUpperCase() + key.slice(1)
                    option = key
                    break
                }

                if (
                  (typeof value === "string" &&
                    !productPrescription.leftEye[option]) ||
                  productPrescription.leftEye[option] === undefined
                ) {
                  let optionValue

                  if (typeof value === "string") {
                    optionValue = { value, label: value }
                  } else if (value.length === 1) {
                    if (value[0].value) {
                      optionValue = value[0]
                    } else {
                      optionValue = { value: value[0], label: value[0] }
                    }
                  } else {
                    optionValue = ""
                  }

                  const newPrescription = {
                    leftEye: {
                      ...productPrescription.leftEye,
                      [option]: optionValue,
                    },
                    rightEye: {
                      ...productPrescription.rightEye,
                      [option]: optionValue,
                    },
                  }

                  setProductPrescription(newPrescription)
                }

                return (
                  <div className="spec-container" key={index}>
                    <div className="select-container">
                      <label>{`Left Eye ${label}`}</label>
                      <Select
                        className={`hubble-select prescription ${
                          (isError || requiredError) && !productPrescription.leftEye[option]
                            ? "error"
                            : ""
                        }`.trim()}
                        ref={option === "power" ? leftPowerRef : null}
                        classNamePrefix="HubbleSelect"
                        value={productPrescription.leftEye[option]}
                        onChange={e => handleSelect("leftEye", option, e)}
                        placeholder="--"
                        isSearchable={false}
                        options={value}
                        isDisabled={
                          typeof value === "string" || value.length === 1
                        }
                        onMenuOpen={() => {
                          if (option !== "power") return
                          setTimeout(() => {
                            leftPowerRef.current.scrollToFocusedOptionOnUpdate = true
                            leftPowerRef.current.setState({
                              focusedOption: value.find(
                                opt => opt.value === "-2.00"
                              ),
                            })
                          }, 0)
                        }}
                      />
                    </div>
                    <div className="select-container">
                      <label>{`Right Eye ${label}`}</label>
                      <Select
                        className={`hubble-select prescription ${
                          (isError || requiredError) && !productPrescription.rightEye[option]
                            ? "error"
                            : ""
                        }`.trim()}
                        ref={option === "power" ? rightPowerRef : null}
                        classNamePrefix="HubbleSelect"
                        value={productPrescription.rightEye[option]}
                        onChange={e => handleSelect("rightEye", option, e)}
                        placeholder="--"
                        options={value}
                        isDisabled={
                          typeof value === "string" || value.length === 1
                        }
                        onMenuOpen={() => {
                          if (option !== "power") return

                          setTimeout(() => {
                            rightPowerRef.current.scrollToFocusedOptionOnUpdate = true
                            rightPowerRef.current.setState({
                              focusedOption: value.find(
                                opt => opt.value === "-2.00"
                              ),
                            })
                          }, 0)
                        }}
                      />
                    </div>
                  </div>
                )
              })}

            <WebpImage
              className="hubble-pack"
              fileName="Pages/Intake/HubblePack.svg"
              alt=""
            />
          </div>
          {brand?.value === "hubble" && (
            <p>
              Hubble lenses have a base curve of 8.6 and a diameter of 14.2.
              They are made of methafilcon A and manufactured by St. Shine
              Optical Co.
            </p>
          )}
          {brand?.value === "hydro" && (
            <p>
              Hydro by Hubble contact lenses have a base curve of 8.4 and a
              diameter of 14.2. They are made of Hioxifilcon A and manufactured
              by Menicon Co.
            </p>
          )}
          {brand?.value === "skyhy" && (
            <p>
              SkyHy by Hubble contact lenses have a base curve of 8.7 and a
              diameter of 14.1. They are made of Olifilcon B and manufactured by
              Visco Vision Inc.
            </p>
          )}

          <p>
            Before you continue, make sure all of this information matches
            what’s on your prescription.
          </p>
        </>
      )}
    </StepWrapper>
  )
}

export default Prescription
