import WebpImage from "../../../WebpImage"
import React, { useContext } from "react"
import HubbleOrderContext from "../../../../context/hubbleOrderContext"

import "./styles.scss"

const StepWrapperV1 = ({
  children,
  title,
  steps,
  currentStep,
  onSubmit,
  className = null,
}) => {
  const hubbleOrderContext = useContext(HubbleOrderContext)

  const currentRef = steps.find(step => step.title === currentStep)?.ref
  const isFinal = steps[steps.length - 1].title === currentStep

  return (
    <div className="step-wrapper" id={className} ref={currentRef}>
      <div className="step-navigation">
        {steps.map(({ title }, index) => (
          <NavigationItem
            id={index + 1}
            title={title}
            active={currentStep === title}
            key={index}
          />
        ))}
      </div>
      <div className="step-body">
        <h2>{title}</h2>
        <div className={`step-content ${className ? [className] : ""}`.trim()}>
          {children}
        </div>
        <div className={`button-container ${isFinal ? "final" : ""}`.trim()}>
          <OrderButton
            label={isFinal ? hubbleOrderContext.submitButtonText : "Next"}
            final={isFinal}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  )
}

const NavigationItem = ({ id, title, active }) => {
  return (
    <div className={`navigation-item ${active ? "active" : ""}`.trim()}>
      <a>{id}</a>
      <span>{title}</span>
    </div>
  )
}

const OrderButton = ({ label, final, onClick, style }) => {
  return (
    <button
      className={`order-button ${final ? "final" : ""} ${style || ""}`.trim()}
      onClick={onClick}
    >
      <span>{label}</span>
      <a>
        <WebpImage objectFit="contain" fileName="Pages/Intake/Arrow.svg" />
      </a>
    </button>
  )
}

export default StepWrapperV1
