import WebpImage from "../../../WebpImage"
import React, { useContext } from "react"
import HubbleOrderContext from "../../../../context/hubbleOrderContext"
import scrollTo from 'gatsby-plugin-smoothscroll'

import "./styles.scss"

const StepWrapperV3 = ({
  children,
  title,
  steps,
  currentStep,
  onSubmit,
  className = null,
  isV3,
  isAvg
}) => {
  const { brand } = useContext(HubbleOrderContext)

  const currentRef = steps.find(step => step.title === currentStep)?.ref
  const isFinal = steps[steps.length - 1].title === currentStep

  const handleStepClick = stepTitle => {
    setTimeout(() => {
      const currentRef = steps.find(({ title }) => title === stepTitle)
      if (stepTitle === 'Brand' && isAvg) {
        scrollTo(`#${brand.value}`)
        return
      }
      currentRef.ref.current.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }

  return (
    <div className="step-wrapper-v3" id={className} ref={currentRef}>
      <div className="step-navigation">
        {steps.map(({ title }, index) => (
          <NavigationItem
            id={index + 1}
            title={title}
            handleStepClick={handleStepClick}
            key={index}
            currentStep={currentStep}
            steps={steps}
          />
        ))}
      </div>
      <div className="step-body">
        <h2 className={isV3 ? "step-title" : ""}>{title}</h2>
        <div className={`step-content ${className ? [className] : ""}`.trim()}>
          {children}
        </div>
        {
          !isFinal &&
          <div className={'button-container'}>
            <OrderButton
              label={"Next"}
              final={false}
              onClick={onSubmit}
              style="step-next"
            />
          </div>
        }
      </div>
    </div>
  )
}

const NavigationItem = ({ id, title, handleStepClick, currentStep, steps }) => {
  const isActive = steps.findIndex(step => step.title === currentStep) >= id - 1

  return (
    <div className={`step ${isActive ? "active" : ""}`.trim()}>
      <a
        className="step-link"
        onClick={() => isActive && handleStepClick(title)}
      >
        <span className="step-number">{id}</span>
      </a>
      <div className="step-number-title">{title}</div>
    </div>
  )
}

const OrderButton = ({ label, final, onClick, style }) => {
  return (
    <button
      className={`order-button ${final ? "final" : ""} ${
        style || ""
      } purple`.trim()}
      onClick={onClick}
    >
      <span>{label}</span>
      <a>
        <WebpImage
          objectFit="contain"
          fileName="Pages/Intake/Arrow-white.svg"
        />
      </a>
    </button>
  )
}

export default StepWrapperV3
